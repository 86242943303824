
//  Contains division Configs for 3D Cube
/*
export const entireConfig = {
    waves: {
                amplitude:  0.023,
                user_amplitude: 0.043,
                user_width: 0.39,
                frequency: 1.4,
                separation: 1.2,
                outer_mask_radius: 9.9,
                outer_mask_falloff: 0.3,
                inner_mask_radius: 0.57,
                inner_mask_falloff: 1
                },
        config_id: {
            Default: {
                scale: 0.5,
                side: 'DOWN',
                solid: {
                    color: '#FFFFFF',
                    depth_color: '#FFFFFF',
                    solid_color: '#FFFFFF',
                    solid_color_strength: 0.0,
                    refraction_scale: 1,
                    density: 0.25,
                    ior: 1.1,
                    dispersion: 0.01,
                    sigma: 0.77
                },
                glass: {
                    color: '#FFFFFF',
                    depth_color: '#486496',
                    solid_color: '#FFFFFF',
                    solid_color_strength: 0.5,
                    refraction_scale: 0.95,
                    density: 0.06,
                    ior: 1.1,
                    dispersion: 0.015,
                    sigma: 1.2
                }
            },
            MaxGrowth: {
                scale: 0.5,
                side: 'DOWN',
                solid: {
                    color: '#00A1E9',
                    depth_color: '#002D97',
                    solid_color: '#FFFFFF',
                    solid_color_strength: 0.0,
                    refraction_scale: 1,
                    density: 0.25,
                    ior: 1.1,
                    dispersion: 0.01,
                    sigma: 0.77
                },
                glass: {
                    color: '#FFFFFF',
                    depth_color: '#486496',
                    solid_color: '#FFFFFF',
                    solid_color_strength: 0.5,
                    refraction_scale: 0.95,
                    density: 0.06,
                    ior: 1.1,
                    dispersion: 0.015,
                    sigma: 1.2
                }
            },
            PureGrowth: {
                scale: 0.5,
                side: 'RIGHT',
                solid: {
                    color: '#00BC78',
                    depth_color: '#067237',
                    solid_color: '#FFFFFF',
                    solid_color_strength: 0.0,
                    refraction_scale: 1,
                    density: 0.33,
                    ior: 1.1,
                    dispersion: 0.01,
                    sigma: 3
                },
                glass: {
                    color: '#FFFFFF',
                    depth_color: '#486496',
                    solid_color: '#FFFFFF',
                    solid_color_strength: 0.5,
                    refraction_scale: 0.95,
                    density: 0.06,
                    ior: 1.1,
                    dispersion: 0.015,
                    sigma: 1.2
                }
            },
            GrowthGuard: {
                scale: 0.5,
                side: 'UP',
                solid: {
                    color: '#BC0A0A',
                    depth_color: '#000000',
                    solid_color: '#FFFFFF',
                    solid_color_strength: 0.0,
                    refraction_scale: 1,
                    density: 0.25,
                    ior: 1.1,
                    dispersion: 0.01,
                    sigma: 0.77
                },
                glass: {
                    color: '#FFFFFF',
                    depth_color: '#486496',
                    solid_color: '#FFFFFF',
                    solid_color_strength: 0.5,
                    refraction_scale: 0.95,
                    density: 0.06,
                    ior: 1.1,
                    dispersion: 0.015,
                    sigma: 1.2
                }
            },
            MaxDiv: {
                scale: 0.5,
                side: 'DOWN',
                solid: {
                    color: '#D0BC00',
                    depth_color: '#FF0000',
                    solid_color: '#FFFFFF',
                    solid_color_strength: 0.0,
                    refraction_scale: 1,
                    density: 0.25,
                    ior: 1.1,
                    dispersion: 0.01,
                    sigma: 0.77
                },
                glass: {
                    color: '#FFFFFF',
                    depth_color: '#486496',
                    solid_color: '#FFFFFF',
                    solid_color_strength: 0.5,
                    refraction_scale: 0.95,
                    density: 0.06,
                    ior: 1.1,
                    dispersion: 0.015,
                    sigma: 1.2
                }
            },
            PureDiv: {
                scale: 0.5,
                side: 'LEFT',
                solid: {
                    color: '#035D23',
                    depth_color: '#003800',
                    solid_color: '#FFFFFF',
                    solid_color_strength: 0.0,
                    refraction_scale: 1,
                    density: 0.25,
                    ior: 1.1,
                    dispersion: 0.01,
                    sigma: 0.77
                },
                glass: {
                    color: '#FFFFFF',
                    depth_color: '#486496',
                    solid_color: '#FFFFFF',
                    solid_color_strength: 0.5,
                    refraction_scale: 0.95,
                    density: 0.06,
                    ior: 1.1,
                    dispersion: 0.015,
                    sigma: 1.2
                }
            },
            DivGuard: {
                scale: 0.5,
                side: 'UP',
                solid: {
                    color: '#01020D',
                    depth_color: '#002D97',
                    solid_color: '#FFFFFF',
                    solid_color_strength: 0.0,
                    refraction_scale: 1,
                    density: 0.25,
                    ior: 1.1,
                    dispersion: 0.01,
                    sigma: 0.77
                },
                glass: {
                    color: '#FFFFFF',
                    depth_color: '#486496',
                    solid_color: '#FFFFFF',
                    solid_color_strength: 0.5,
                    refraction_scale: 0.95,
                    density: 0.06,
                    ior: 1.1,
                    dispersion: 0.015,
                    sigma: 1.2
                }
            },
            RedPerspective: {
                scale: 0.5,
                side: 'DOWN',
                solid: {
                    color: '#D0BC00',
                    depth_color: '#FF0000',
                    solid_color: '#FFFFFF',
                    solid_color_strength: 0.0,
                    refraction_scale: 1,
                    density: 0.25,
                    ior: 1.1,
                    dispersion: 0.01,
                    sigma: 0.77
                },
                glass: {
                    color: '#BC0A0A',
                    depth_color: '#000000',
                    solid_color: '#FFFFFF',
                    solid_color_strength: 0.0,
                    refraction_scale: 1,
                    density: 0.25,
                    ior: 1.1,
                    dispersion: 0.01,
                    sigma: 0.77
                }
            },
            GreenPerspective: {
                scale: 0.5,
                side: 'LEFT',
                solid: {
                    color: '#035D23',
                    depth_color: '#003800',
                    solid_color: '#FFFFFF',
                    solid_color_strength: 0.0,
                    refraction_scale: 1,
                    density: 0.25,
                    ior: 1.1,
                    dispersion: 0.01,
                    sigma: 0.77
                },
                glass: {
                    color: '#00BC78',
                    depth_color: '#067237',
                    solid_color: '#FFFFFF',
                    solid_color_strength: 0.0,
                    refraction_scale: 1,
                    density: 0.33,
                    ior: 1.1,
                    dispersion: 0.01,
                    sigma: 3
                }
            },
            BluePerspective: {
                scale: 0.5,
                side: 'UP',
                solid: {
                    color: '#01020D',
                    depth_color: '#002D97',
                    solid_color: '#FFFFFF',
                    solid_color_strength: 0.0,
                    refraction_scale: 1,
                    density: 0.25,
                    ior: 1.1,
                    dispersion: 0.01,
                    sigma: 0.77
                },
                glass: {
                    color: '#00A1E9',
                    depth_color: '#002D97',
                    solid_color: '#FFFFFF',
                    solid_color_strength: 0.0,
                    refraction_scale: 1,
                    density: 0.25,
                    ior: 1.1,
                    dispersion: 0.01,
                    sigma: 0.77
                }
            }
        }
    }
*/


export const entireConfig = {
    waves: {
                amplitude:  0.023,
                user_amplitude: 0.043,
                user_width: 0.39,
                frequency: 1.4,
                separation: 1.2,
                outer_mask_radius: 9.9,
                outer_mask_falloff: 0.3,
                inner_mask_radius: 0.57,
                inner_mask_falloff: 1
                },
        config_id: {
            Default: {
                scale: 0.5,
                side: 'DOWN',
                solid: {
                    color: '#FFFFFF',
                    depth_color: '#486496',
                    solid_color: '#FFFFFF',
                    solid_color_strength: 1,
                    refraction_scale: 1,
                    density: 0.05,
                    ior: 1.1,
                    dispersion: 0.01,
                    sigma: 1.7
                },
                glass: {
                    color: '#FFFFFF',
                    depth_color: '#486496',
                    solid_color: '#FFFFFF',
                    solid_color_strength: 1,
                    refraction_scale: 1,
                    density: 0.05,
                    ior: 1.1,
                    dispersion: 0.01,
                    sigma: 1.7
                }
            },
            MaxGrowth: {
                scale: 0.5,
                side: 'DOWN',
                solid: {
                    color: '#5EB6E4',
                    depth_color: '#FFFFFF',
                    solid_color: '#FFFFFF',
                    solid_color_strength: 0,
                    refraction_scale: 10,
                    density: 0.98,
                    ior: 1.1,
                    dispersion: 0,
                    sigma: 0.77
                },
                glass: {
                    color: '#FFFFFF',
                    depth_color: '#486496',
                    solid_color: '#FFFFFF',
                    solid_color_strength: 1,
                    refraction_scale: 1,
                    density: 0.05,
                    ior: 1.1,
                    dispersion: 0.01,
                    sigma: 1.7
                }
            },
            PureGrowth: {
                scale: 0.5,
                side: 'RIGHT',
                solid: {
                    color: '#C7DB6D',
                    depth_color: '#C7DB6D',
                    solid_color: '#C7DB6D',
                    solid_color_strength: 1.1,
                    refraction_scale: 5.2,
                    density: 1,
                    ior: 1.1,
                    dispersion: 0.01,
                    sigma: 0.77
                },
                glass: {
                    color: '#FFFFFF',
                    depth_color: '#486496',
                    solid_color: '#FFFFFF',
                    solid_color_strength: 1,
                    refraction_scale: 1,
                    density: 0.05,
                    ior: 1.1,
                    dispersion: 0.01,
                    sigma: 1.7
                }
            },
            GrowthGuard: {
                scale: 0.5,
                side: 'UP',
                solid: {
                    color: '#FF002C',
                    depth_color: '#C9E4FC',
                    solid_color: '#FFFFFF',
                    solid_color_strength: 0,
                    refraction_scale: 10,
                    density: 0.98,
                    ior: 1.1,
                    dispersion: 0,
                    sigma: 0.77
                },
                glass: {
                    color: '#FFFFFF',
                    depth_color: '#486496',
                    solid_color: '#FFFFFF',
                    solid_color_strength: 1,
                    refraction_scale: 1,
                    density: 0.05,
                    ior: 1.1,
                    dispersion: 0.01,
                    sigma: 1.7
                }
            },
            MaxDiv: {
                scale: 0.5,
                side: 'DOWN',
                solid: {
                    color: '#E0B600',
                    depth_color: '#E0B600',
                    solid_color: '#E0B600',
                    solid_color_strength: 1.2,
                    refraction_scale: 5.2,
                    density: 1,
                    ior: 1.1,
                    dispersion: 0.01,
                    sigma: 0.77
                },
                glass: {
                    color: '#FFFFFF',
                    depth_color: '#486496',
                    solid_color: '#FFFFFF',
                    solid_color_strength: 1,
                    refraction_scale: 1,
                    density: 0.05,
                    ior: 1.1,
                    dispersion: 0.01,
                    sigma: 1.7
                }
            },
            PureDiv: {
                scale: 0.5,
                side: 'LEFT',
                solid: {
                    color: '#3F9C35',
                    depth_color: '#FFFFFF',
                    solid_color: '#FFFFFF',
                    solid_color_strength: 0,
                    refraction_scale: 10,
                    density: 0.98,
                    ior: 1.1,
                    dispersion: 0,
                    sigma: 0.77
                },
                glass: {
                    color: '#FFFFFF',
                    depth_color: '#486496',
                    solid_color: '#FFFFFF',
                    solid_color_strength: 1,
                    refraction_scale: 1,
                    density: 0.05,
                    ior: 1.1,
                    dispersion: 0.01,
                    sigma: 1.7
                }
            },
            DivGuard: {
                scale: 0.5,
                side: 'UP',
                solid: {
                    color: '#1E1E42',
                    depth_color: '#FFFFFF',
                    solid_color: '#FFFFFF',
                    solid_color_strength: 0,
                    refraction_scale: 10,
                    density: 0.98,
                    ior: 1.1,
                    dispersion: 0,
                    sigma: 0.77
                },
                glass: {
                    color: '#FFFFFF',
                    depth_color: '#486496',
                    solid_color: '#FFFFFF',
                    solid_color_strength: 1,
                    refraction_scale: 1,
                    density: 0.05,
                    ior: 1.1,
                    dispersion: 0.01,
                    sigma: 1.7
                }
            },
            RedPerspective: {
                scale: 0.5,
                side: 'DOWN',
                solid: {
                    color: '#E0B600',
                    depth_color: '#E0B600',
                    solid_color: '#E0B600',
                    solid_color_strength: 1.2,
                    refraction_scale: 5.2,
                    density: 1,
                    ior: 1.1,
                    dispersion: 0.01,
                    sigma: 0.77
                },
                glass: {
                    color: '#FF002C',
                    depth_color: '#C9E4FC',
                    solid_color: '#FFFFFF',
                    solid_color_strength: 0,
                    refraction_scale: 10,
                    density: 0.98,
                    ior: 1.1,
                    dispersion: 0,
                    sigma: 0.77
                }
            },
            GreenPerspective: {
                scale: 0.5,
                side: 'LEFT',
                solid: {
                    color: '#3F9C35',
                    depth_color: '#FFFFFF',
                    solid_color: '#FFFFFF',
                    solid_color_strength: 0,
                    refraction_scale: 10,
                    density: 0.98,
                    ior: 1.1,
                    dispersion: 0,
                    sigma: 0.77
                },
                glass: {
                    color: '#C7DB6D',
                    depth_color: '#C7DB6D',
                    solid_color: '#C7DB6D',
                    solid_color_strength: 1.1,
                    refraction_scale: 5.2,
                    density: 1,
                    ior: 1.1,
                    dispersion: 0.01,
                    sigma: 0.77
                }
            },
            BluePerspective: {
                scale: 0.5,
                side: 'UP',
                solid: {
                    color: '#1E1E42',
                    depth_color: '#FFFFFF',
                    solid_color: '#FFFFFF',
                    solid_color_strength: 0,
                    refraction_scale: 10,
                    density: 0.98,
                    ior: 1.1,
                    dispersion: 0,
                    sigma: 0.77
                },
                glass: {
                    color: '#5EB6E4',
                    depth_color: '#FFFFFF',
                    solid_color: '#FFFFFF',
                    solid_color_strength: 0,
                    refraction_scale: 10,
                    density: 0.98,
                    ior: 1.1,
                    dispersion: 0,
                    sigma: 0.77
                }
            },

        }
    }