import React from 'react';
import { useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import { Input, Button, Tabs, Statistic, Select } from 'antd';
import CurrencyFormat from 'react-currency-format';
import { isEmpty, isUndefined, round, sortBy } from 'lodash';
import { currencyFormat, whiteSecurityImageSuffix } from '../utils';
import { sectionTitle, sectionDescription, stepInfo, parseSecurityData, securityAutoComplete, moreInformationContent } from './utils';
import { securityListEndPoint } from '../../../common/endpoint';
import axiosConfig from '../../../axiosConfig';
import './styles.scss';
import sectionLogo from '../../../images/white-division.png';
import CustomImage from '../../common/customImage';
import arrowRight from '../../../images/arrow-right.png';
import arrowRightSmall from '../../../images/arrow-right-small.png';
const { TabPane } = Tabs;

const CurrentPortfolio = ({
  updateActiveSecurity,
  updateTotalShare,
  triggerShowAlternate,
  updateETA,
  className,
  activeContent
}) => {
  const selectRef = useRef(null);
  const [securityHeld, setSecurityHeld] = useState('');
  const [activeSecurityContent, setActiveSecurityContent] = useState();
  const [lastPrice, setLastPrice] = useState(0.00);
  const [yieldValue, setYieldValue] = useState(0.00);
  const [growthETA, setGrowthETA] = useState(0);
  const [dividentETA, setDividentETA] = useState(0);
  const [securityList, setSecurityList] = useState([]);
  const [forcastDivident, setForcastDivident] = useState(0);
  const [totalHolding, setTotalHolding] = useState(0);
  const [shareQuantity, setShareQuantity] = useState('');
  const [hideNextStep, setHideNextStep] = useState(true);
  const [shareDisplayText, setShareDisplayText] = useState('');
  const [securityValue, setSecurityValue] = useState(undefined);
  const defaultShareQuantity = 1000;

  /* Security list API */
  useEffect(() => {
    axiosConfig.get(securityListEndPoint).then((response) => {
      if (response?.status === 200) {
        setSecurityList(sortBy(response?.data, ['longName']));
      }
    });
  }, []);

  useEffect(() => {
    if (!isEmpty(activeContent)) {
      onHandleSecurityHeld(activeContent, defaultShareQuantity)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeContent]);


  const onHandleSecurityHeld = (option, initialShareQuantity) => {
    setSecurityHeld(option.ticker);
    setSecurityValue(`${option.longName} (${option.ticker})`)
    const activeSecurity = option;
    setActiveSecurityContent(activeSecurity);
    updateActiveSecurity(activeSecurity);
    if (!isEmpty(activeSecurity)) {
      const activeLastPrice = activeSecurity.lastPrice.toFixed(2);
      setLastPrice(activeLastPrice);
      const forwardYield = activeSecurity.forwardDivYield * 100;
      setYieldValue(forwardYield.toFixed(2));

      const shareQuantityValue = !isUndefined(initialShareQuantity) ? initialShareQuantity : shareQuantity;
      if (!isUndefined(shareQuantityValue) && !isEmpty(shareQuantityValue.toString())) {
        onHandleNumberHeld(shareQuantityValue, activeLastPrice, forwardYield.toFixed(2));
      }
    } else {
      setLastPrice(0.00);
      setYieldValue(0.00);
    }
  }

  const onHandleNumberHeld = (value, activeLastPrice, activeForwardYield) => {
    setShareDisplayText(currencyFormat(value));
    setShareQuantity(value);
    updateTotalShare(value);
    const growthETA = value;
    const incomeETA = value;
    setGrowthETA(growthETA);
    setDividentETA(incomeETA);
    const lastPriceValue = !isUndefined(activeLastPrice) ? activeLastPrice : lastPrice;
    const yieldContentValue = !isUndefined(activeForwardYield) ? activeForwardYield : yieldValue;
    const totalHoldingValue = value * lastPriceValue;
    const forCastDividentValue = ((totalHoldingValue * yieldContentValue) / 100);
    setTotalHolding(totalHoldingValue.toFixed(2));
    setForcastDivident(round(forCastDividentValue, 2));
    updateETA(growthETA, incomeETA);
  }

  const triggerNextStep = () => {
    triggerShowAlternate(true);
    setHideNextStep(true);
  }

  return (
    <div className={cn('current-portfolio', className)}>
      <div>
        <h1 className="text-5xl font-bold flex intro-label">Equity Optimiser</h1>
      </div>
      <div>
        <div className='section-header'>
          <div className="section-label">
            {sectionTitle}
          </div>
          <p className="section-description pt-2">
            {sectionDescription}
          </p>

          <div className='step-info mt-4'>
            <span className='step-label'>
              STEP 1
            </span>
            <span className='step-description pl-4'>
              {stepInfo}
            </span>
          </div>
          <div className='section-body pt-4'>
            <div className='section-logo py-4 flex justify-center'>
              <img src={sectionLogo} alt='current portofolio' />
            </div>
            <div className='secuirty-autocomplete flex justify-center pt-4'>
              <Select ref={selectRef}
                showSearch
                options={parseSecurityData(securityList)}
                placeholder={securityAutoComplete}
                optionFilterProp="children"
                dropdownClassName={'autcomplete-dropdown'}
                dropdownMatchSelectWidth={true}
                filterOption={(inputValue, option) => {
                  return option.longName.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 || option.ticker.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
                }
                showArrow={false}
                onSelect={(value, option) => {
                  selectRef.current.blur();
                  onHandleSecurityHeld(option)
                }}
                value={securityValue}
              />
            </div>
            <div className='security-info pt-2'>
              <span className='float-left'>Last Price
                <CurrencyFormat className='pl-1' value={lastPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
              </span>
              <span className='float-right'>Yield {yieldValue}%</span>
            </div>

            {!isEmpty(securityHeld) && (
              <>
                <div className='security-logo py-12 flex justify-center'>
                  <CustomImage
                    ImagePath={`/logos/${activeSecurityContent.region}/${activeSecurityContent.ticker}${whiteSecurityImageSuffix}.svg`}
                    alt={activeSecurityContent.ticker}
                    defaultImagePath={`/logos/logoPending.svg`}
                    defaultImageWidth={125}
                  />
                </div>
                <div className='share-info p-4'>
                  <div className='share-info-header flex'>
                    <div className='label pb-4 pr-5'> SHARES TO BE CONVERTED</div>
                    <div className='label pb-4 pl-5'> THIS HOLDING CAN BE CONVERTED INTO</div>
                  </div>
                  <div className='flex items-center'>
                    <div className='share-content-section pr-10'>
                      <Input
                        className='share-input'
                        placeholder='SHARE HOLDING QTY'
                        onChange={(e) => onHandleNumberHeld(e.target.value.replace(/[^0-9.-]+/g, ""))}
                        value={shareDisplayText}
                      >
                      </Input>
                    </div>
                    <div className='arrow m1-10'>
                      <img src={arrowRight} alt=''></img>
                    </div>
                    <div className='share-content-section pl-5 w-1/2'>
                      <div className='eta-content flex justify-around mb-2'>
                        <span className='growth-count'>
                          <Statistic className='statistic-content' value={growthETA} />
                        </span>
                        <span className='growth-text'>Growth ETAs</span>
                      </div>
                      <div className='eta-content flex justify-around'>
                        <span className='income-count'>
                          <Statistic className='statistic-content' value={dividentETA} />
                        </span>
                        <span className='income-text'>Dividend ETAs</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='derived-content mt-4'>
                  <div className='values flex justify-around'>
                    <div className='p-2'>
                      <CurrencyFormat value={forcastDivident} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
                    </div>
                    <div className='p-2'>
                      <CurrencyFormat value={totalHolding} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
                    </div>
                  </div>
                  <div className='flex justify-around'>
                    <div className='label m-2'>FORCAST ANNUAL DIVIDEND</div>
                    <div className='label m-2'>TOTAL HOLDING VALUE</div>
                  </div>
                </div>
              </>
            )}
          </div>
          {!isEmpty(shareQuantity) && (
            // PBT-29
            <div className='hidden'>
              <div className='security-tabs pt-4'>
                <Tabs defaultActiveKey="1">
                  <TabPane tab="More information" key="1">
                    <p className='content'>
                      {moreInformationContent}
                    </p>
                    {/* <Button type="text" className='read-more'>
                      Read more...
                    </Button> */}
                  </TabPane>
                </Tabs>
              </div>
              {!hideNextStep && (
                <div className='footer-info pt-6'>
                  <Button type="primary" onClick={() => triggerNextStep()}>
                    NEXT STEP
                    <img className='next-icon' src={arrowRightSmall} alt='next-icon'></img>
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CurrentPortfolio;