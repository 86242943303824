import React from 'react';
import { useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import { Select, Slider, Button } from 'antd';
import CurrencyFormat from 'react-currency-format';
import { isEmpty, max, upperCase, sortBy, isUndefined, map } from 'lodash';
import CubeModelo, { setNormalizedCubeSlider, scaleCubeModel, greenPerspectiveZone, bluePerspectiveZone, redPerspectiveZone, defaultZoneSelect, maxDivZoneSelect, pureDivZoneSelect, divGuardZoneSelect, growthGuardZoneSelect, pureGrowthZoneSelect, maxGrowthZoneSelect } from './ETACubeModelo/Index';
import { securityHeaderLabel, parseSecurityData, securityAutoComplete, prismAnnualFee, exampleSecurityContent, exampleGreenETA, exampleRedETA, exampleBlueETA, whiteSecurityImageSuffix } from './utils';
import axiosConfig from '../../axiosConfig';
import './styles.scss';
import { etaDetailsEndpoint, securityListEndPoint } from '../../common/endpoint';
import PayoffChart from '../payoff-chart';
import CustomImage from '../../components/common/customImage';
import UpwardIcon from "../../images/upwards.png";
import DownwardIcon from "../../images/downwards.png";
import CapitalGuardGrowth from "../../images/capitalguardgrowth.png";
import CapitalGuardIncome from "../../images/capitalguardincome.png";
import Transition from "../EquityConverter/utils/Transition"

const ETAShowCase = ({
  activeSecurityList,  // Active Security Content from MarketList
  className,
  updateETADetails,
  activeETAList,          // Active ETA Content from MarketList
}) => {
  const [activeSecurity, setActiveSecurity] = useState({});
  const [securityList, setSecurityList] = useState([exampleSecurityContent]);
  const [lastPrice, setLastPrice] = useState('');
  const [yieldValue, setYieldValue] = useState('');
  const [blueState, setBlueValue] = useState(0.00);
  const [redState, setRedValue] = useState(0.00);
  const [greenState, setGreenValue] = useState(0.00);
  const [sliderValue, setSliderValue] = useState(50);
  const [etaType, setEtaType] = useState('');                // Either "red", "blue" or 'green'
  const [activeEtaType, setActiveEtaType] = useState('');   // Either "Growth" or "Income"
  const [greenContent, setGreenContent] = useState({});
  const [blueContent, setBlueContent] = useState({});
  const [redContent, setRedContent] = useState({});
  const selectRef = useRef(null);
  const initialSliderPosition = 50;
  const [viewMode, setViewMode] = useState('cube');
  // Example Stock will be enabled later
  //const [securityValue, setSecurityValue] = useState(`${exampleSecurityContent.longName} (${exampleSecurityContent.ticker})`);
  const [securityValue, setSecurityValue] = useState(undefined); // "Undefined is used inorder to render the Placeholder in the ANTD framework"
  const [growthProfile, setGrowthProfile] = useState({
    blue: {
      label: 'MaxGrowth',
      incomeLabel: 'DivGuard',
      subLabel: 'ETA',
      riskProfile: 'AGGRESSIVE',
      renderOnlybar: false,
      colorCode: '#65CDF3',
      type: 'blue',
      multipler: [],
      rangeLabel: 'GROWTH MULTIPIER',
      valueAllocation: 'Price growth only',
      capitalExposure: '1ST CAPITAL EXPOSURE',
      expandedHeaderColor: 'linear-gradient(130.53deg, #65CDF3 0%, rgba(101, 205, 243, 0.5) 100%), #1C59A8',
      expandedContentColor: 'linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), linear-gradient(130.53deg, #65CDF3 0%, rgba(101, 205, 243, 0.5) 100%), #1C59A8',
      collapsedHeaderColor: 'linear-gradient(130.53deg, #65CDF3 0%, rgba(101, 205, 243, 0.5) 100%), #1C59A8',
      collapsedContentColor: ' linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), linear-gradient(130.53deg, #65CDF3 0%, rgba(101, 205, 243, 0.5) 100%), #1C59A8'

    },
    green: {
      label: 'PureGrowth',
      incomeLabel: 'PureDiv',
      subLabel: 'ETA',
      riskProfile: 'MARKET',
      renderOnlybar: false,
      colorCode: '#C7DB6D',
      type: 'green',
      multipler: [],
      rangeLabel: 'GROWTH MULTIPIER',
      valueAllocation: 'Price growth only',
      capitalExposure: 'Shared',
      expandedHeaderColor: 'linear-gradient(130.53deg, #C7DB6D 0%, rgba(199, 219, 109, 0.5) 100%), #73BD59',
      expandedContentColor: 'linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), linear-gradient(130.53deg, #C7DB6D 0%, rgba(199, 219, 109, 0.5) 100%), #73BD59',
      collapsedHeaderColor: 'linear-gradient(130.53deg, #C7DB6D 0%, rgba(199, 219, 109, 0.5) 100%), #73BD59',
      collapsedContentColor: 'linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), linear-gradient(130.53deg, #C7DB6D 0%, rgba(199, 219, 109, 0.5) 100%), #73BD59'
    },
    red: {
      label: 'GrowthGuard',
      incomeLabel: 'MaxDiv',
      subLabel: 'ETA',
      riskProfile: 'GUARDED',
      renderOnlybar: false,
      colorCode: '#DB3155',
      type: 'red',
      multipler: [],
      rangeLabel: 'GROWTH MULTIPIER',
      valueAllocation: 'Price growth only',
      isCapitalGuardRequired: true,
      capitalGuardImage: CapitalGuardGrowth,
      capitalExposure: '2nd capital exposure',
      expandedHeaderColor: 'linear-gradient(130.53deg, #DB3155 0%, rgba(219, 49, 85, 0.6) 100%), rgba(245, 189, 26, 0.8)',
      expandedContentColor: 'linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), linear-gradient(130.53deg, #DB3155 0%, rgba(219, 49, 85, 0.6) 100%), rgba(245, 189, 26, 0.8)',
      collapsedHeaderColor: 'linear-gradient(130.53deg, #DB3155 0%, rgba(219, 49, 85, 0.6) 100%), rgba(245, 189, 26, 0.8)',
      collapsedContentColor: 'linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), linear-gradient(130.53deg, #DB3155 0%, rgba(219, 49, 85, 0.6) 100%), #F5BD1A'
    }
  });
  const [incomeProfile, setIncomeProfile] = useState({
    red: {
      label: 'MaxDiv',
      growthLabel: 'GrowthGuard',
      subLabel: 'ETA',
      riskProfile: 'AGGRESSIVE',
      colorCode: '#F5BD1A',
      type: 'red',
      yield: [],
      rangeLabel: 'Dividend yield',
      valueAllocation: 'Dividends only',
      capitalExposure: '1ST CAPITAL EXPOSURE',
      expandedHeaderColor: 'linear-gradient(130.53deg, #F5BD1A 0%, rgba(245, 189, 26, 0.7) 100%), #DB3155',
      expandedContentColor: 'linear-gradient(0deg, rgba(255, 255, 255, 0.65), rgba(255, 255, 255, 0.65)), linear-gradient(130.53deg, #F5BD1A 0%, rgba(245, 189, 26, 0.7) 100%), #DB3155',
      collapsedHeaderColor: 'linear-gradient(130.53deg, #F5BD1A 0%, rgba(245, 189, 26, 0.7) 100%), #DB3155',
      collapsedContentColor: 'linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), linear-gradient(130.53deg, #F5BD1A 0%, rgba(245, 189, 26, 0.7) 100%), #DB3155'
    },
    green: {
      label: 'PureDiv',
      growthLabel: 'PureGrowth',
      subLabel: 'ETA',
      riskProfile: 'MARKET',
      colorCode: '#73BD59',
      type: 'green',
      yield: [],
      rangeLabel: 'Dividend yield',
      valueAllocation: 'Dividends only',
      capitalExposure: 'shared',
      expandedHeaderColor: 'linear-gradient(131.96deg, #73BD59 -2.45%, rgba(115, 189, 89, 0.4) 100%), #C7DB6D',
      expandedContentColor: 'linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), linear-gradient(131.96deg, #73BD59 -2.45%, rgba(115, 189, 89, 0.4) 100%), #C7DB6D',
      collapsedHeaderColor: 'linear-gradient(131.96deg, #73BD59 -2.45%, rgba(115, 189, 89, 0.4) 100%), #C7DB6D',
      collapsedContentColor: 'linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), linear-gradient(130.53deg, #F5BD1A 0%, rgba(245, 189, 26, 0.7) 100%), #DB3155'
    },
    blue: {
      label: 'DivGuard',
      growthLabel: 'MaxGrowth',
      subLabel: 'ETA',
      riskProfile: 'GUARDED',
      colorCode: '#1C59A8',
      type: 'blue',
      yield: [],
      rangeLabel: 'Dividend yield',
      valueAllocation: 'Dividends only',
      capitalExposure: '2ND CAPITAL EXPOSURE',
      isCapitalGuardRequired: true,
      capitalGuardImage: CapitalGuardIncome,
      expandedHeaderColor: 'linear-gradient(130.53deg, #1C59A8 0%, rgba(28, 89, 168, 0.5) 100%), #65CDF3',
      expandedContentColor: 'linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), linear-gradient(130.53deg, #1C59A8 0%, rgba(28, 89, 168, 0.5) 100%), #65CDF3',
      collapsedHeaderColor: ' linear-gradient(130.53deg, #1C59A8 0%, rgba(28, 89, 168, 0.5) 100%), #65CDF3',
      collapsedContentColor: 'linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), linear-gradient(130.53deg, #1C59A8 0%, rgba(28, 89, 168, 0.5) 100%), #65CDF3'
    }
  });

  const growthHeader = {
    label: 'Growth',
    subLabel: 'ETAs',
    info: 'Receive All Price Growth',
    hideHeader: false,
    mode: 'normal',
  }

  const incomeHeader = {
    label: 'Dividend',
    subLabel: 'ETAs',
    info: 'Receive All Dividends Paid',
    hideHeader: false,
    mode: 'normal',
  }

  /**
    * @description This method renders the Header content
    * @param {object} headerContent Header content details
    * @param {string} className Custom Classname
  */
  const renderHeader = (headerContent, className) => (
    <div className={cn('eta-header mb-12 mt-10', className)}>
      <div className='header-label'>
        {headerContent.label}
        <span className='font-bold tracking-wider'>{` ${headerContent.subLabel}`}</span>
      </div>
      <div className='header-info'>
        {headerContent.info}
      </div>
    </div>
  )

  // payoff chart variables
  const dimensions = {
    width: 294,
    height: 150,
    margin: 15,
  };

  /**
    * @description Triggering the Security from the Market List
  */
  useEffect(() => {
    if (!isEmpty(activeSecurityList)) {
      setSecurityValue(`${activeSecurityList.longName} (${activeSecurityList.ticker})`)
      onHandleSecurityHeld(activeSecurityList.longName, activeSecurityList);
    } else {
      // Example Stock will be enabled later
      // onHandleSecurityHeld(exampleSecurityContent.longName, exampleSecurityContent, false);
    }
    setEtaType('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSecurityList, activeETAList]);

  /**
    * @description Triggering the ETA from the MarketList
  */
  useEffect(() => {
    if (!isEmpty(activeETAList)) {
      const etaValue = activeETAList.etaType.toLowerCase();
      const type = activeETAList.selectedEtaType.toLowerCase();
      const etaData = fetchEtaContent(etaValue);
      const derivedETAContent = calculateETAData(activeETAList, lastPrice, yieldValue / 100, initialSliderPosition);
      etaData.etaCallback(derivedETAContent)
      updateETAType(etaValue, type, true, derivedETAContent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeETAList]);

  /**
    * @description Triggering the Security list API
  */
  useEffect(() => {
    axiosConfig.get(securityListEndPoint).then((response) => {
      if (response?.status === 200) {
        const securityAvailableList = response?.data;
        securityAvailableList.push(exampleSecurityContent);
        setSecurityList(sortBy(securityAvailableList, ['longName']));
      }
    });
  }, []);

  /**
    * @description This methods reset the expanded ETA content to its initial state by setting the slider at 50 before expanding the clicked ETA content
    * @param {string} etaType ETA type (color) info
  */
  const resetExpandedETA = (etaType) => {
    const etaData = fetchEtaContent(etaType);
    const derivedETAContent = calculateETAData(etaData.etaContent, lastPrice, yieldValue / 100, initialSliderPosition);
    etaData.etaCallback(derivedETAContent);
    setSliderValue(initialSliderPosition);
  }

  /**
    * @description This methods update the currently clicked ETA content withits etaColor and etaType and render the Cube
    * @param {string} etaType ETA type (color) info
    * @param {string} activeEtaTypeValue ETA profile info (Growth or income)
    * @param {boolean} skipResetExpandedETA Flag to determine whethe we need to reset the already Expanded ETA content
  */
  const updateETAType = (newEtaType, activeEtaTypeValue, skipResetExpandedETA, derivedETAContent) => {
    //Cube
    defaultZoneSelect(100);
    // Skipping the reset the ETA while navigating between securities
    if (!skipResetExpandedETA && !isEmpty(etaType)) {
      // Resets the already expanded state to it initial state before expanding the new one
      resetExpandedETA(etaType);
    }
    setEtaType(newEtaType);
    setActiveEtaType(activeEtaTypeValue);

    // set the cube to correct colours and highlighted section.
    if (!isEmpty(newEtaType)) {
      if (newEtaType === 'green') {
        const greenContentValue = isUndefined(derivedETAContent) ? greenContent : derivedETAContent;
        if (activeEtaTypeValue === 'growth') {
          pureGrowthZoneSelect(greenContentValue.incomePercentageofShare);
        } else {
          pureDivZoneSelect(greenContentValue.growthPercentageofShare);
        }
      } else if (newEtaType === 'blue') {
        const blueContentValue = isUndefined(derivedETAContent) ? blueContent : derivedETAContent;
        if (activeEtaTypeValue === 'growth') {
          maxGrowthZoneSelect(blueContentValue.incomePercentageofShare);
        } else {
          divGuardZoneSelect(blueContentValue.growthPercentageofShare);
        }
      } else {
        const redContentValue = isUndefined(derivedETAContent) ? redContent : derivedETAContent;
        if (activeEtaTypeValue === 'growth') {
          growthGuardZoneSelect(redContentValue.incomePercentageofShare);
        } else {
          maxDivZoneSelect(redContentValue.growthPercentageofShare);
        }
      }
    }
  }


  const perspectiveSelect = (perspectiveType) => {
    if (perspectiveType === 'red') {
      redPerspectiveZone()
    } else if (perspectiveType === 'blue') {
      bluePerspectiveZone()
    } else if (perspectiveType === 'green') {
      greenPerspectiveZone()
    }
  }

  /**
    * @description This method renders the Example Content
  */
  const renderExampleETADetails = () => {
    populateGreenContent(initialSliderPosition);
    populateRedContent(initialSliderPosition);
    populateBlueContent(initialSliderPosition);
  };

  /**
    * @description This method renders the Green ETA example Content
    * @param {string} defaultSliderRange ETA Slider value
  */
  const populateGreenContent = (defaultSliderRange) => {
    const greenETAContent = calculateETAData(exampleGreenETA, exampleSecurityContent.lastPrice, exampleSecurityContent.forwardDivYield, defaultSliderRange);
    setGreenValue(exampleGreenETA);
    const growthData = { ...growthProfile };
    growthData.green.multipler = greenETAContent.growthValue.split(".");
    setGrowthProfile(growthData);
    const incomeData = { ...incomeProfile };
    incomeData.green.yield = greenETAContent.dividentValue.split(".");
    setIncomeProfile(incomeData);
    updateGreenETAContent(greenETAContent);
  }

  /**
    * @description This method renders the Red ETA example Content
    * @param {string} defaultSliderRange ETA Slider value
  */
  const populateRedContent = (defaultSliderRange) => {
    const redETAContent = calculateETAData(exampleRedETA, exampleSecurityContent.lastPrice, exampleSecurityContent.forwardDivYield, defaultSliderRange);
    setRedValue(exampleRedETA);
    const growthData = { ...growthProfile };
    growthData.red.multipler = redETAContent.growthValue.split(".");
    setGrowthProfile(growthData);
    const incomeData = { ...incomeProfile };
    incomeData.red.yield = redETAContent.dividentValue.split(".");
    setIncomeProfile(incomeData);
    updateRedETAContent(redETAContent);
  }

  /**
    * @description This method renders the Blue ETA example Content
    * @param {string} defaultSliderRange ETA Slider value
  */
  const populateBlueContent = (defaultSliderRange) => {
    const blueETAContent = calculateETAData(exampleBlueETA, exampleSecurityContent.lastPrice, exampleSecurityContent.forwardDivYield, defaultSliderRange);
    setBlueValue(exampleBlueETA);
    const growthData = { ...growthProfile };
    growthData.blue.multipler = blueETAContent.growthValue.split(".");
    setGrowthProfile(growthData);
    const incomeData = { ...incomeProfile };
    incomeData.blue.yield = blueETAContent.dividentValue.split(".");
    setIncomeProfile(incomeData);
    updateBlueETAContent(blueETAContent);
  }

  /**
    * @description This method handles the Security Dropdown Selection and updates the Last Price and Yield Value
    * @param {string} selectedSecurityHeld selected secuirty content label
    * @param {object} securityOption All the details about the Selected Security content
    * @param {boolean} skipETACall Flag to determine whether API call is required to fetch the ETA Content
  */
  const onHandleSecurityHeld = (selectedSecurityHeld, securityOption, skipETACall) => {
    triggerETAPanelContent('', '', ''); // Resetting the panel in the right
    updateETAType('', '', true);
    const currentTickerCode = securityOption.ticker;
    const activeSecurity = securityOption;
    setActiveSecurity(activeSecurity);
    setSecurityValue(`${securityOption.longName} (${securityOption.ticker})`)
    if (!isEmpty(activeSecurity)) {
      setLastPrice(activeSecurity.lastPrice.toFixed(2));
      const forwardYield = activeSecurity.forwardDivYield * 100;
      setYieldValue(forwardYield.toFixed(2));
    }

    // Resetting the Slider to the initialvalue while switching secuirties
    setSliderValue(initialSliderPosition);
    if (currentTickerCode === exampleSecurityContent.ticker) {
      skipETACall = true;
      renderExampleETADetails();
    }
    if (!skipETACall) {
      fetchGreenETAContent(activeSecurity.lastPrice.toFixed(2), currentTickerCode, activeSecurity.forwardDivYield);
      fetchRedETAContent(activeSecurity.lastPrice.toFixed(2), currentTickerCode, activeSecurity.forwardDivYield);
      fetchBlueETAContent(activeSecurity.lastPrice.toFixed(2), currentTickerCode, activeSecurity.forwardDivYield);
    }
  }

  /**
    * @description This method returns the active ETA content object and its callback function
    * @param {string} etaType selected secuirty content label
  */
  const fetchEtaContent = (etaType) => {
    let etaContent = '';
    let etaCallback = '';
    if (etaType === 'green') {
      etaContent = greenState;
      etaCallback = updateGreenETAContent;
    } else if (etaType === 'blue') {
      etaContent = blueState;
      etaCallback = updateBlueETAContent;
    } else if (etaType === 'red') {
      etaContent = redState;
      etaCallback = updateRedETAContent;
    }
    return { etaContent, etaCallback }
  }

  /**
    * @description This method handles the slider navigation
    * @param {string} sliderRange Slider range
  */
  const handleSlider = (sliderRange) => {
    setSliderValue(sliderRange);
    // Slider will work only if any one of the ETA is in expanded state
    if (!isEmpty(etaType)) {
      const etaData = fetchEtaContent(etaType);
      const derivedETAContent = calculateETAData(etaData.etaContent, lastPrice, yieldValue / 100, sliderRange);
      etaData.etaCallback(derivedETAContent);

      //Slider to resize cube accordingly
      // **
      setNormalizedCubeSlider(etaType, activeEtaType, derivedETAContent.normalisedGrowthPrice);
    }
  }

  /**
    * @description This method to perform the calculation to populate the ETA Contents
    * @param {object} etaData Active ETA object
    * @param {string} lastPrice Last price of the selected security
    * @param {string} yieldValueFromObject Yield Value of the Selected Security
    * @param {string} slider Slider position
  */
  const calculateETAData = (etaData, lastPrice, yieldValueFromObject, slider) => {
    const growthEstablishmentPrice = etaData.growthEstablishmentPrice;
    const incomeEstablishmentPrice = etaData.incomeEstablishmentPrice;
    const growthLastPrice = etaData.growthLastPrice;
    const incomeLastPrice = etaData.incomeLastPrice;
    let impliedUnderlyingPrice = growthLastPrice + incomeLastPrice;
    impliedUnderlyingPrice = parseFloat(impliedUnderlyingPrice);
    let sliderRange = !isUndefined(slider) && !isEmpty(slider.toString()) ? slider : sliderValue;
    let adjustGrowthPrice = '';
    if (sliderRange >= 50) {
      const incomeCalcPrice = (incomeLastPrice * (1 - ((sliderRange - 50) / 50)));
      const maxValue = max([0.01, incomeCalcPrice]);
      adjustGrowthPrice = (impliedUnderlyingPrice - maxValue);
    } else {
      const growthCalcPrice = growthLastPrice * (1 - ((50 - sliderRange) / 50));
      adjustGrowthPrice = max([0.01, growthCalcPrice]);
    }
    const multipler = lastPrice / adjustGrowthPrice;
    const adjustedIncomePrice = (impliedUnderlyingPrice - adjustGrowthPrice);
    const normalisedScale = 100;
    // eslint-disable-next-line no-unused-vars
    const normalisedGrowthPrice = (adjustGrowthPrice * (normalisedScale / impliedUnderlyingPrice)); // This will be used while rendering the cube
    // eslint-disable-next-line no-unused-vars
    const growthMulitplier = (lastPrice / adjustGrowthPrice); // This will be used while rendering the cube
    const growthValue = multipler.toFixed(2);

    const growthETAPrice = parseFloat(adjustGrowthPrice);
    const growthPercentageofShare = 100 * adjustGrowthPrice / impliedUnderlyingPrice;
    const incomeETAPrice = parseFloat(adjustedIncomePrice);
    const incomePercentageofShare = 100 * adjustedIncomePrice / impliedUnderlyingPrice;
    const derivedDivident = 100 * ((lastPrice * (yieldValueFromObject)) - (prismAnnualFee * (growthEstablishmentPrice + incomeEstablishmentPrice))) / parseFloat(adjustedIncomePrice);
    const dividentValue = derivedDivident.toFixed(2);
    // eslint-disable-next-line no-unused-vars
    const incomeForwardYield = dividentValue; // This will be used while rendering the cube
    const growthSymbol = etaData.growthSymbol;
    const incomeSymbol = etaData.incomeSymbol;
    return { growthValue, dividentValue, growthETAPrice, growthPercentageofShare, incomeETAPrice, incomePercentageofShare, growthSymbol, incomeSymbol, normalisedGrowthPrice, growthLastPrice, growthEstablishmentPrice, incomeEstablishmentPrice, incomeLastPrice };
  }

  /**
    * @description This method update the RED etatype
    * @param {object} calculatedContent Calculated content for the Active Red ETA Content
  */

  const updateRedETAContent = (calculatedContent) => {
    const growthData = { ...growthProfile };
    growthData.red.multipler = calculatedContent.growthValue.split(".");
    setGrowthProfile(growthData);
    const incomeData = { ...incomeProfile };
    incomeData.red.yield = calculatedContent.dividentValue.split(".");
    setIncomeProfile(incomeData);
    setRedContent({
      growthETAPrice: calculatedContent.growthETAPrice,
      growthPercentageofShare: calculatedContent.growthPercentageofShare,
      incomeETAPrice: calculatedContent.incomeETAPrice,
      incomePercentageofShare: calculatedContent.incomePercentageofShare,
      growthSymbol: calculatedContent.growthSymbol,
      incomeSymbol: calculatedContent.incomeSymbol,
      growthLastPrice: calculatedContent.growthLastPrice,
      incomeLastPrice: calculatedContent.incomeLastPrice,
      growthEstablishmentPrice: calculatedContent.growthEstablishmentPrice,
      incomeEstablishmentPrice: calculatedContent.incomeEstablishmentPrice,
    });
  }

  /**
    * @description This method update the BLUE etatype
    * @param {object} calculatedContent Calculated content for the Active Blue ETA Content
  */
  const updateBlueETAContent = (calculatedContent) => {
    const growthData = { ...growthProfile };
    growthData.blue.multipler = calculatedContent.growthValue.split(".");
    setGrowthProfile(growthData);
    const incomeData = { ...incomeProfile };
    incomeData.blue.yield = calculatedContent.dividentValue.split(".");
    setIncomeProfile(incomeData);
    setBlueContent({
      growthETAPrice: calculatedContent.growthETAPrice,
      growthPercentageofShare: calculatedContent.growthPercentageofShare,
      incomeETAPrice: calculatedContent.incomeETAPrice,
      incomePercentageofShare: calculatedContent.incomePercentageofShare,
      growthSymbol: calculatedContent.growthSymbol,
      incomeSymbol: calculatedContent.incomeSymbol,
      growthLastPrice: calculatedContent.growthLastPrice,
      incomeLastPrice: calculatedContent.incomeLastPrice,
      growthEstablishmentPrice: calculatedContent.growthEstablishmentPrice,
      incomeEstablishmentPrice: calculatedContent.incomeEstablishmentPrice,
    });
  }

  /**
    * @description This method update the GREEN etatype
    * @param {object} calculatedContent Calculated content for the Active Green ETA Content
  */
  const updateGreenETAContent = (calculatedContent) => {
    const growthData = { ...growthProfile };
    growthData.green.multipler = calculatedContent.growthValue.split(".");
    setGrowthProfile(growthData);
    const incomeData = { ...incomeProfile };
    incomeData.green.yield = calculatedContent.dividentValue.split(".");
    setIncomeProfile(incomeData);
    setGreenContent({
      growthETAPrice: calculatedContent.growthETAPrice,
      growthPercentageofShare: calculatedContent.growthPercentageofShare,
      incomeETAPrice: calculatedContent.incomeETAPrice,
      incomePercentageofShare: calculatedContent.incomePercentageofShare,
      growthSymbol: calculatedContent.growthSymbol,
      incomeSymbol: calculatedContent.incomeSymbol,
      growthLastPrice: calculatedContent.growthLastPrice,
      incomeLastPrice: calculatedContent.incomeLastPrice,
      growthEstablishmentPrice: calculatedContent.growthEstablishmentPrice,
      incomeEstablishmentPrice: calculatedContent.incomeEstablishmentPrice,
    });
  }

  /**
    * @description This method fetches the GREEN ETA content from API
    * @param {string} lastPrice Last price of the selected security
    * @param {string} selectedSecurityHeld Ticker code of the Selected Security
    * @param {string} yieldValue Yield Value of the Selected Security
    * @todo Need to replace this with the Promise and combine everything
  */

  const fetchGreenETAContent = (lastPrice, selectedSecurityHeld, yieldValue) => {
    const eta = "GREEN";
    if (!isEmpty(selectedSecurityHeld) && !isEmpty(eta)) {
      axiosConfig.get(`${etaDetailsEndpoint}/${selectedSecurityHeld}/${upperCase(eta)}`).then((response) => {
        if (response?.status === 200) {
          const etaData = response.data;
          const calculatedContent = calculateETAData(etaData, lastPrice, yieldValue, initialSliderPosition);
          setGreenValue(etaData);
          updateGreenETAContent(calculatedContent);
        }
      });
    }
  }

  /**
    * @description This method fetches the RED ETA content from API
    * @param {string} lastPrice Last price of the selected security
    * @param {string} selectedSecurityHeld Ticker code of the Selected Security
    * @param {string} yieldValue Yield Value of the Selected Security
    * @todo Need to replace this with the Promise and combine everything
  */
  const fetchRedETAContent = (lastPrice, selectedSecurityHeld, yieldValue) => {
    const eta = "RED";
    if (!isEmpty(selectedSecurityHeld) && !isEmpty(eta)) {
      axiosConfig.get(`${etaDetailsEndpoint}/${selectedSecurityHeld}/${upperCase(eta)}`).then((response) => {
        if (response?.status === 200) {
          const etaData = response.data;
          setRedValue(etaData);
          const calculatedContent = calculateETAData(etaData, lastPrice, yieldValue, initialSliderPosition);
          updateRedETAContent(calculatedContent);
        }
      });
    }
  }

  /**
    * @description This method fetches the BLUE ETA content from API
    * @param {string} lastPrice Last price of the selected security
    * @param {string} selectedSecurityHeld Ticker code of the Selected Security
    * @param {string} yieldValue Yield Value of the Selected Security
    * @todo Need to replace this with the Promise and combine everything
  */
  const fetchBlueETAContent = (lastPrice, selectedSecurityHeld, yieldValue) => {
    const eta = "BLUE";
    if (!isEmpty(selectedSecurityHeld) && !isEmpty(eta)) {
      axiosConfig.get(`${etaDetailsEndpoint}/${selectedSecurityHeld}/${upperCase(eta)}`).then((response) => {
        if (response?.status === 200) {
          const etaData = response.data;
          const calculatedContent = calculateETAData(etaData, lastPrice, yieldValue, initialSliderPosition);
          setBlueValue(etaData);
          updateBlueETAContent(calculatedContent);
        }
      });
    }
  }

  /**
    * @description This method to render the ETA content based on Growth or Income data
    * @param {object} profile Growth Object or Income object
    * @param {string} profileType growth or income
  */
  const renderProfile = (profile, profileType) => (
    <>
      <div className={cn('eta-profile-info flex flex-row mt-8',
        (profileType === 'growth') && (((!isEmpty(activeEtaType) && !isEmpty(etaType) && etaType === profile.type) || (!isEmpty(activeEtaType) && !isEmpty(etaType))) ? "justify-start" : "justify-end"),
        (profileType === 'income') && (((!isEmpty(activeEtaType) && !isEmpty(etaType) && etaType !== profile.type)) ? "justify-end" : "justify-start"),
        { "text-right": profileType === 'growth' && (isEmpty(etaType) || isEmpty(activeEtaType)) },
        { "opacity-30": !isEmpty(etaType) && isEmpty(activeSecurity) && etaType !== profile.type }
      )}
        onClick={() => {
          if (isEmpty(activeSecurity)) {
            // Updating only the type if the security is empty, otherwise Cube will be re-rendered
            setEtaType(profile.type)
            // set the cube to correct colours and highlighted section.
            // hardcoded values provided
            if (profile.type === 'green') {
              if (profileType === 'growth') {
                pureGrowthZoneSelect(50);
              } else {
                pureDivZoneSelect(50);
              }
            } else if (profile.type === 'blue') {
              if (profileType === 'growth') {
                maxGrowthZoneSelect(75);
              } else {
                divGuardZoneSelect(25);
              }
            } else if (profile.type === 'red') {
              if (profileType === 'growth') {
                growthGuardZoneSelect(34);
              } else {
                maxDivZoneSelect(66);
              }
            }

          } else {
            updateETAType(profile.type, profileType);
            triggerETAPanelContent(profile.type, profileType, profile.label);
          }
        }
        }
      >
        {/* Rendering the ETA calculated content for the Income type */}
        {!isEmpty(profile.yield) && !isEmpty(activeSecurity) && isEmpty(etaType) && profileType === 'income' && ((!isEmpty(activeEtaType) && activeEtaType === 'income') || isEmpty(activeEtaType)) && (
          <div className='flex flex-col eta-profile-content income-profile pr-2 text-right cursor-pointer w-4/12'>
            {renderRangeContent(profile.yield[0], `.${profile.yield[1]}%`, profile.rangeLabel, profileType)}
          </div>
        )}
        <div className={cn('flex flex-col w-8/12', {
          'pl-2': profileType === 'income' && (isEmpty(etaType) || isEmpty(activeEtaType)),
          'pr-2': profileType === 'growth' && (isEmpty(etaType) || isEmpty(activeEtaType))
        })} onClick={
          // Triggers the ETA More info label on the ETA Intro content
          () => {
            triggerETAPanelContent(profile.type, profileType, profile.label);
          }}>
          {(
            (!isEmpty(activeEtaType) && !isEmpty(etaType) && etaType === profile.type) ||
            (isEmpty(activeEtaType) && isEmpty(etaType)) ||
            (!isEmpty(activeEtaType) && isEmpty(etaType)) ||
            (isEmpty(activeEtaType) && !isEmpty(etaType)))
            && (
              <div className='w-full items-baseline eta-label-content cursor-pointer'>
                <span className='eta-profile-info__title'>{profile.label} </span>
                <span className='eta-profile-info__sub-title pl-1 font-bold'>{profile.subLabel} </span>
              </div>
            )}
          {!(!isEmpty(activeEtaType) && !isEmpty(etaType) && etaType === profile.type) && (
            <div className={cn('eta-profile-info__bar cursor-pointer', {
              'w-11/12': isEmpty(activeEtaType) || isEmpty(etaType),
              'w-3/4': !isEmpty(activeEtaType) && !isEmpty(etaType),
              'self-end': ((profileType === 'growth' && !(!isEmpty(activeEtaType) && !isEmpty(etaType))) || (!isEmpty(activeEtaType) && !isEmpty(etaType) && profileType === 'income'))
            }
            )}
              style={{ 'background-color': profile.colorCode, color: profile.colorCode }} >
            </div>
          )}
          {!(!isEmpty(activeEtaType) && !isEmpty(etaType)) && (
            <div className='eta-profile-info__risk-profile cursor-pointer mt-2 upper-case'>
              <span className='font-bold'>RISK PROFILE: </span>
              <span className=''>{profile.riskProfile}</span>
            </div>
          )}
        </div>
        {/* Rendering the ETA calculated content for the Growth type */}
        {!isEmpty(profile.multipler) && !isEmpty(activeSecurity) && isEmpty(etaType) && profileType === 'growth' && ((!isEmpty(activeEtaType) && activeEtaType === 'growth') || isEmpty(activeEtaType)) && (
          <div className='flex flex-col eta-profile-content growth-profile pl-2 text-left cursor-pointer'>
            {renderRangeContent(profile.multipler[0], `.${profile.multipler[1]}x`, profile.rangeLabel, profileType)}
          </div>
        )}
      </div>
      <Transition className='eta-transition-block' isOpen={etaType === profile.type} isHorizontal={false}>
        {!isEmpty(activeEtaType) && !isEmpty(etaType) && (
          renderDetailedView(profile, profileType, getActiveContent())
        )}
      </Transition>
    </>
  )
  /**
    * @description This method to fetch the active ETA type content based on the etaColor code
  */
  const getActiveContent = () => {
    if (etaType === 'green')
      return greenContent;
    if (etaType === 'red')
      return redContent;
    if (etaType === 'blue')
      return blueContent;
  }

  /**
    * @description This method renders the Detailed list of the ETA based on the Growth or Income and ETA color
    * @param {object} profile Growth Object or Income object
    * @param {string} profileType growth or income
    * @param {object} content Calculated ETA content object
  */
  const renderDetailedView = (profile, profileType, content) => {
    let capitalGuard = [];
    if (profile.isCapitalGuardRequired) {
      const contentGuardValue = profileType === 'growth' ? content.growthPercentageofShare : content.incomePercentageofShare
      const guardValue = (100 - contentGuardValue).toFixed(2);
      capitalGuard = guardValue.split(".");
    }
    return (
      <>
        {profileType === 'growth' && activeEtaType === "growth" && (
          renderExpandedETADetails(profile, content.growthEstablishmentPrice, 'Growth multiple', profile.multipler[0], `.${profile.multipler[1]}x`, content.growthETAPrice, content.growthPercentageofShare, content.growthLastPrice, capitalGuard)
        )}

        {profileType === 'growth' && activeEtaType === "income" && (
          renderETACollapsedView(profile, profile.incomeLabel, content.growthETAPrice, "Multipler", `${profile.multipler[0]}.${profile.multipler[1]}x`)
        )}
        {profileType === 'income' && activeEtaType === "income" && (
          renderExpandedETADetails(profile, content.incomeEstablishmentPrice, 'Target running yield', profile.yield[0], `.${profile.yield[1]}%`, content.incomeETAPrice, content.incomePercentageofShare, content.incomeLastPrice, capitalGuard)
        )}
        {profileType === 'income' && activeEtaType === "growth" && (
          renderETACollapsedView(profile, profile.growthLabel, content.incomeETAPrice, "Yield", `${profile.yield[0]}.${profile.yield[1]}%`)
        )}
      </>
    )
  }

  /**
    * @description Method to handle the Upwards and Downwards click action. Minimum value is 0 and Maximum value is 100
    * @param {string} sliderType Either incremental or decremental slider
  */
  const triggerSliderValue = (sliderType) => {
    let slider = sliderValue;
    if (sliderType === "increment") {
      slider += 1;
      if (slider > 95) {
        slider = 95;
      }
    }
    if (sliderType === "decrement") {
      slider -= 1;
      if (slider < 5) {
        slider = 5;
      }
    }
    handleSlider(slider);
  }

  /**
    * @description Method to render the Expanded view of ETA Details
    * @param {object} profile Growth Object or Income object
    * @param {string} establishmentPrice Establishment price of the ETA
    * @param {string} rangeLabel Label to be displaced for the ETA ( either "Multipier" or "Yield")
    * @param {string} rangeValue  "Multipier" or "Yield" value before decimal point. Values are splitted into two object to set the different CSS styles
    * @param {string} rangeDecimal "Multipier" or "Yield" value after decimal point. Values are splitted into two object to set the different CSS styles
    * @param {string} bidPrice Bid price of the ETA
    * @param {string} sharePercent Share Percent price of the ETA
    * @param {string} lastOffer Last offer price of the ETA
    * @param {string} capitalGuard Capital Guard of the ETA
  */
  const renderExpandedETADetails = (profile, establishmentPrice, rangeLabel, rangeValue, rangeDecimal, bidPrice, sharePercent, lastOffer, capitalGuard) => {
    return (
      <div className='eta-expanded py-1 flex flex-col mb-10'>
        <div
          className='header-content pt-1 pb-0.5 px-4 flex flex-col'
          style={{ background: profile.expandedHeaderColor }}
        >
          <div className='w-full flex allocation-section pb-1'>
            <span className='w-1/2 flex flex-col uppercase pr-2'>
              <label className='label-item'>
                Value Allocation
              </label>
              <span className='value-item'>
                {profile.valueAllocation}
              </span>
            </span>
            <span className='w-1/2 flex flex-col uppercase'>
              <label className='label-item'>
                Risk Allocation
              </label>
              <span className='value-item'>
                {profile.capitalExposure}
              </span>
            </span>
          </div>
          <div className='price-section flex py-1'>
            <span className='w-1/2 flex flex-col uppercase pr-2'>
              <label className='label-item'>
                Establishment
              </label>
              <span>
                <span className='label-item pr-1'> Price </span>
                <CurrencyFormat className='value-item' value={establishmentPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
              </span>
            </span>
            <span className='w-1/2 flex flex-col uppercase'>
              <label className='label-item'>
                TERM
              </label>
              <span className='value-item'>
                9.5 years remaining
              </span>
            </span>
          </div>
          <div className='eta-more-info-section flex py-1'>
            <span className='w-1/3 flex flex-col uppercase pr-2'>
              <span className='w-full'>
                <label className='label-item pr-2'>
                  CODE
                </label>
                <span className='value-item'>
                  {activeSecurity.ticker}
                </span>
              </span>
            </span>
            <span className='w-2/3 flex flex-col uppercase cursor-pointer' onClick={
              // Triggers the ETA More info label on the ETA Intro content
              () => {
                triggerETAPanelContent(profile.type, activeEtaType, profile.label);
              }
            }>
              <span className='value-item learn-more text-center'>
                Learn more about this ETA
              </span>
            </span>
          </div>
        </div>
        <div className='eta-content-section py-5 flex justify-center'
          style={{ background: profile.expandedContentColor }}
        >
          <div className={cn('flex-col flex justify-center', {
            'w-1/2 ': profile.isCapitalGuardRequired
          })}>
            <div className='flex flex-row items-baseline eta-label-content justify-center px-2 flex-wrap leading-9'>
              <span className='section-range-value font-bold'>{rangeValue}</span>
              <span className='section-range-decimal font-bold'>{rangeDecimal}</span>
            </div>
            <div className='uppercase text-center'>
              {rangeLabel}
            </div>
          </div>
          {profile.isCapitalGuardRequired && (
            <div className='relative capital-guard-container px-2 flex flex-col items-center w-1/2'>
              <img src={profile.capitalGuardImage} alt=''></img>
              <div className='capital-guard-content'>
                <div className='capital-guard-value'>
                  <span className='capital-value-range font-bold'>{capitalGuard[0]}</span>
                  <span className='capital-value-decimal'>.{capitalGuard[1]}%</span>
                </div>
                <div className='uppercase capital-guard-label text-center'> Capital <br /> Guard</div>
              </div>
            </div>
          )}
        </div>
        <div className='footer-content'>
          <div className='flex-row flex w-full'>
            <div className='w-1/2 flex flex-col justify-center section-separator share-target-info pt-1'>
              <span className='font-bold text-center'>
                <CurrencyFormat value={bidPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
              </span>
              <span className='uppercase text-center'>Target bid price</span>
            </div>
            <div className='w-1/2 flex-row flex slider-buttons'>
              <span className='w-1/2 up-arrow flex justify-center items-center' title={sliderValue} onClick={() => triggerSliderValue("increment")}>
                <img src={UpwardIcon} alt='' />
              </span>
              <span className='w-1/2 down-arrow flex justify-center items-center' title={sliderValue} onClick={() => triggerSliderValue("decrement")}>
                <img src={DownwardIcon} alt='' />
              </span>
            </div>
          </div>
          <div className='flex-row flex w-full'>
            <div className='w-1/2 flex flex-col justify-center section-separator uppercase'>
              <span className='font-bold text-center'>
                <CurrencyFormat value={sharePercent} displayType={'text'} thousandSeparator={false} suffix={'%'} decimalScale={2} fixedDecimalScale={true} />
              </span>
              <span className='text-center'>of Share</span>
            </div>
            <div className='w-1/2 flex-col flex text-center uppercase last-offer-info'>
              <span className=''>last offer</span>
              <span className=''>
                <CurrencyFormat value={lastOffer} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
              </span>
            </div>
          </div>
          <div className='flex-row w-full uppercase add-portofolio py-0.5 text-center cursor-pointer'>
            Add bid to portfolio
          </div>
        </div>
      </div>
    )
  }

  /**
    * @description Method to render the Collapsed view of the ETA Details
    * @param {object} profile Growth Object or Income object
    * @param {string} etaLabel ETA Label to be displaced for the ETA
    * @param {string} matchPrice Match price of the ETA
    * @param {string} rangeTitle Profile Range title either "Multipier" or "Yield"
    * @param {string} rangeValue  "Multipier" or "Yield" value
  */
  const renderETACollapsedView = (profile, etaLabel, matchPrice, rangeTitle, rangeValue) => (
    <div
      className='eta-collpased flex flex-col mt-1'
      style={{ background: profile.collapsedHeaderColor }}
    >
      <div className='px-2 py-2'>
        <p className='eta-collpased__info'>
          <span className='font-bold'>{profile.label} ETAs</span> are combined with <span className='font-bold'>{etaLabel} ETAs </span>  within the {profile.type} colour Perspective.
        </p>
        <p className='pt-2 eta-collpased__info'>
          For a successful price match to take place, {profile.label} investors would have to accept:
        </p>
      </div>
      <div
        className='eta-content w-full flex p-2'
        style={{ background: profile.collapsedContentColor }}
      >
        <div className='flex-col w-1/2 text-center'>
          <div className='font-bold'>
            <CurrencyFormat className='bold' value={matchPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
          </div>
          <div> Match Price</div>
        </div>
        <div className='flex-col border-l-2 border-white-500 content-spacing text-center w-1/2'>
          <div className='font-bold'>{rangeValue}</div>
          <div> {rangeTitle} </div>
        </div>
      </div>
    </div>
  )

  /**
    * @description Method to render the Range while selecting a security. Slider is 50 by default
    * @param {string} rangeValue  "Multipier" or "Yield" value before decimal point. Values are splitted into two object to set the different CSS styles
    * @param {string} rangeDecimalValue "Multipier" or "Yield" value after decimal point. Values are splitted into two object to set the different CSS styles
    * @param {string} rangeLabel Label to be displaced for the ETA ( either "Multipier" or "Yield")
    * @param {string} profileType Either Growth or Income
  */
  const renderRangeContent = (rangeValue, rangeDecimalValue, rangeLabel, profileType) => (
    <>
      <div className='eta-profile-content__range'>
        <span className='font-bold eta-profile-content__range-value'>{rangeValue}</span>
        <span className='eta-profile-content__range-decimal'>{rangeDecimalValue}</span>
      </div>
      <div className={cn('uppercase eta-profile-content__range-label',
        { 'self-end': profileType === 'income' })}>
        {rangeLabel}
      </div>
    </>
  )

  /**
    * @description Method to render the ETA details under the Eta button
  */
  const getETAInfo = () => (
    `Connecting ${growthProfile[etaType].label} Investors with ${incomeProfile[etaType].label} Investors`
  )

  /**
    * @description Method to reset all the content to its initial State on clicking the logo
  */
  const resetShowCase = () => {
    setSecurityValue(undefined);
    setLastPrice('');
    setYieldValue('');
    setActiveSecurity({});
    setViewMode('cube');
    setBlueValue(0.00);
    setRedValue(0.00);
    setGreenValue(0.00);
    setSliderValue(50);
    setEtaType('');
    setActiveEtaType('');
    setGreenContent({});
    setBlueContent({});
    setRedContent({});
    const growthData = { ...growthProfile };
    growthData.green.multipler = [];
    growthData.blue.multipler = [];
    growthData.red.multipler = [];
    setGrowthProfile(growthData);
    const incomeData = { ...incomeProfile };
    incomeData.green.yield = [];
    incomeData.blue.yield = [];
    incomeData.red.yield = [];
    setIncomeProfile(incomeData);
    defaultZoneSelect(100);
    triggerETAPanelContent('', '', '');
  }

  /**
    * @description Method to trigger the panel in the Intro Panel to render the ETA details
  */
  const triggerETAPanelContent = (etaColor, etaType, etaLabel, etaMode) => {
    if (typeof updateETADetails != "undefined") {
      updateETADetails(etaColor, etaType, etaLabel, etaMode);
    }
  }

  return (
    <>
      <CubeModelo />
      <div className={cn('eta-showcase container-cube', className)}>
        <div className="eta-index-model">  </div>
        <div className='flex flex-col eta-header eta-index-container'>
          {/* Security Dropdown */}
          <div className='security-autocomplete flex justify-center pt-4 flex-col'>
            <div className='security-autocomplete__label text-center pb-2'> {securityHeaderLabel}</div>
            <Select ref={selectRef}
              showSearch
              options={parseSecurityData(securityList)}
              placeholder={securityAutoComplete}
              optionFilterProp="children"
              dropdownClassName={'autcomplete-dropdown'}
              dropdownMatchSelectWidth={true}
              filterOption={(inputValue, option) => {
                return option.longName.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 || option.ticker.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              }
              }
              value={securityValue}
              showArrow={false}
              onSelect={(value, option) => {
                selectRef.current.blur();
                onHandleSecurityHeld(value, option)
              }}
            />
          </div>
          <div className='security-info pt-2 eta-index-container'>
            {!isUndefined(lastPrice) && !isEmpty(lastPrice.toString()) && (
              <>
                <span className='float-left'>Last Price
                  <CurrencyFormat className='pl-2' value={lastPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
                </span>
                <span className='float-right'>Yield
                  <CurrencyFormat className='pl-2' value={yieldValue} displayType={'text'} thousandSeparator={false} decimalScale={2} fixedDecimalScale={true} />
                  %</span>
              </>
            )}
          </div>
          {!isEmpty(activeSecurity) && activeSecurity.ticker !== 'EXS' && (
            <div className='eta-header__security-logo pt-4 pb-2 flex justify-center' >
              <CustomImage
                ImagePath={`/logos/${activeSecurity.region}/${activeSecurity.ticker}${whiteSecurityImageSuffix}.svg`}
                alt={activeSecurity.ticker}
                defaultImagePath={`/logos/logoPending.svg`}
                defaultImageWidth={125}
                className='cursor-pointer'
                clickActionCallback={resetShowCase}
              />
            </div>
          )}
        </div>
        <>

          <div id="eta-cube" className='flex eta-content px-10 justify-center w-full eta-index-container'>
            <div className='flex flex-col'>
              <div className='eta-showcase-content gap-6 flex flex-row w-full'>
                <div className='growth-col'>
                  {isEmpty(activeSecurity) && renderHeader(growthHeader, "text-right")}
                  <div className={cn('growth-profile-container',
                    { "opacity-30": !isEmpty(activeEtaType) && isEmpty(etaType) && activeEtaType !== 'growth' }
                  )}>
                    {map(growthProfile, (profile, i) => (
                      renderProfile(profile, "growth")
                    ))}
                  </div>
                </div>
                <div className='cube-col flex items-center flex-col'>
                  {/* Rendering the Payoff Chart based on the Mode */}
                  {activeEtaType === "growth" && !isEmpty(etaType) && viewMode === 'growth' ? (
                    <div className='showCasePayoffChart'>
                      <div className='chartHeader'><span
                        className={cn(
                          "font-extrabold",
                          {
                            'growth-blue-text': etaType === 'blue',
                            'growth-red-text': etaType === 'red',
                            'growth-green-text': etaType === 'green',
                          }
                        )}
                      >{
                          ((etaType === 'green') && "PureGrowth")
                          || ((etaType === 'blue') && "MaxGrowth")
                          || ((etaType === 'red') && "GrowthGuard")
                        } Profile</span> vs <span className='font-bold'>Underlying share</span></div>
                      <PayoffChart
                        dimensions={dimensions}
                        underlyingSecurityPrice={lastPrice}
                        eta1EstablishmentPrice={
                          ((etaType === 'green') && greenContent.growthETAPrice)
                          || ((etaType === 'blue') && blueContent.growthETAPrice)
                          || ((etaType === 'red') && redContent.growthETAPrice)
                        }
                        // used growthETAPrice for etaLastPrice temporarily
                        eta1LastPrice={
                          ((etaType === 'green') && greenContent.growthETAPrice)
                          || ((etaType === 'blue') && blueContent.growthETAPrice)
                          || ((etaType === 'red') && redContent.growthETAPrice)
                        }
                        eta1Type={
                          ((etaType === 'green') && "PureGrowth")
                          || ((etaType === 'blue') && "MaxGrowth")
                          || ((etaType === 'red') && "GrowthGuard")
                        }
                        eta1Value={
                          ((etaType === 'green') && greenContent.growthETAPrice)
                          || ((etaType === 'blue') && blueContent.growthETAPrice)
                          || ((etaType === 'red') && redContent.growthETAPrice)
                        }
                        underlyingEstab1Price={
                          ((etaType === 'green') && (greenContent.growthETAPrice + greenContent.incomeETAPrice))
                          || ((etaType === 'blue') && (blueContent.growthETAPrice + blueContent.incomeETAPrice))
                          || ((etaType === 'red') && (redContent.growthETAPrice + redContent.incomeETAPrice))
                        }
                        showLegend={false}
                        showAxisLabel={false}
                        fillColor={true}
                        showDottedLine={true}
                        mouseOver={false}
                      />
                    </div>
                  ) : (
                    <div className='section-image '> {/* Rendering the Cube */}

                    </div>
                  )}
                  <div className='w-full mt-4 eta-slider-content'>
                    <Slider
                      className='eta-slider hidden'
                      min={0}
                      max={100}
                      defaultValue={initialSliderPosition}
                      value={sliderValue}
                      onChange={(val) => handleSlider(val)}
                      disabled={isEmpty(activeEtaType)}
                    />
                  </div>
                  <div className='w-full'>
                  </div>
                </div>
                <div className='dividend-col dividend-items-align'>
                  {isEmpty(activeSecurity) && renderHeader(incomeHeader, "text-left")}
                  <div className={cn('income-profile-container',
                    { "opacity-30": !isEmpty(activeEtaType) && isEmpty(etaType) && activeEtaType !== 'income' }
                  )}>
                    {map(incomeProfile, (profile, i) => (
                      renderProfile(profile, "income")
                    ))}
                  </div>
                </div>
              </div>
              <div className='flex lg:flex-row flex-col eta-action-items mt-2 justify-center flex-wrap'>
                {isEmpty(activeSecurity) && (
                  <>
                    <Button type="primary" className={cn('m-2 uppercase', { 'active': etaType === 'blue' })} onClick={() => { setEtaType('blue'); perspectiveSelect('blue'); }}>
                      BLUE PERSPECTIVE
                    </Button>
                    <Button type="primary" className={cn('m-2 uppercase', { 'active': etaType === 'green' })} onClick={() => { setEtaType('green'); perspectiveSelect('green'); }}>
                      GREEN PERSPECTIVE
                    </Button><Button type="primary" className={cn('m-2 uppercase', { 'active': etaType === 'red' })} onClick={() => { setEtaType('red'); perspectiveSelect('red'); }}>
                      RED PERSPECTIVE
                    </Button>
                  </>
                )}
                {!isEmpty(activeSecurity) && isEmpty(etaType) && (
                  <>
                    <Button type="primary" className={cn('m-2 uppercase', { 'active': etaType === 'blue' })} onClick={() => setActiveEtaType('growth')}>
                      Growth ETA comparisons
                    </Button>
                    <Button type="primary" className={cn('m-2 uppercase', { 'active': etaType === 'green' })} onClick={() => setActiveEtaType('income')}>
                      Dividend eta comparisons
                    </Button>
                  </>
                )}

                {!isEmpty(activeEtaType) && !isEmpty(etaType) && (
                  <>
                    <Button type="primary" className={cn('m-2 uppercase', { 'active': viewMode === 'cube' })} onClick={() => setViewMode('cube')}>
                      cube view
                    </Button>
                    {activeEtaType === "income" ? (
                      <Button type="primary" disabled className={cn('m-2 uppercase', { 'active': viewMode === 'yield' })} onClick={() => setViewMode('yield')}>
                        Yield profile
                      </Button>
                    ) : (
                      <Button type="primary" className={cn('m-2 uppercase', { 'active': viewMode === 'growth' })} onClick={() => setViewMode('growth')}>
                        Growth profile
                      </Button>
                    )}
                    <Button type="primary" disabled className={cn('m-2 uppercase', { 'active': viewMode === 'bid' })} onClick={() => setViewMode('bid')}>
                      Bid matching
                    </Button>
                  </>
                )}
              </div>
              <div className='flex flex-row justify-center pt-4'>
                {!isEmpty(etaType) && isEmpty(activeSecurity) && (
                  <div className='eta-content__info mt-4 flex justify-center'>
                    {getETAInfo()}
                  </div>
                )}
              </div>
            </div>
          </div>

        </>
      </div>
    </>
  );
};

export default ETAShowCase;