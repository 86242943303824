/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useState, useEffect } from 'react';
import { filter, isEmpty, upperCase, capitalize, isUndefined } from 'lodash';
import cn from 'classnames';
import CurrencyFormat from 'react-currency-format';
import { Button, Statistic } from 'antd';
import { etaDropdownData, etaTitleMapping, whiteSecurityImageSuffix } from '../utils';
import { etaDetailsEndpoint } from '../../../common/endpoint';
import CustomImage from '../../common/customImage';
import axiosConfig from '../../../axiosConfig';
import './styles.scss';
import Dropdown from '../utils/Dropdown';
import initialSectionLogo from '../../../images/white-eta-icon.png';
import arrowRightSmall from '../../../images/arrow-right-small.png';

const AlternatePosition = ({
  activeSecurity,
  activeSecurityContent,
  updateETALogo,
  updateETADetails,
  updateETAValue,
  triggerOptimiseBlock,
  growthETA,
  incomeETA,
  className,
  activeETAList
}) => {

  const sectionTitle = 'Alternate ETA Position';
  const sectionDescription = '.';
  const stepInfo = 'Choose an ETA pairing to convert your shares to';
  const dropdownPlaceHolder = 'SELECT AN ETA';

  const [sectionLogo, setSectionLogo] = useState(initialSectionLogo);
  const [eta, setETA] = useState('');
  const [etaLastGrowthPrice, setEtaLastGrowthPrice] = useState('');
  const [etaLastIncomePrice, setEtaLastIncomePrice] = useState('');
  const [hideNextStep, setHideNextStep] = useState(true);

  useEffect(() => {
    if (!isEmpty(activeSecurity) && !isEmpty(eta)) {
      axiosConfig.get(`${etaDetailsEndpoint}/${activeSecurity}/${upperCase(eta)}`).then((response) => {
        if (response?.status === 200) {
          const etaMetaDataResponse = response.data;
          updateETADetails(etaMetaDataResponse);
          setEtaLastGrowthPrice(etaMetaDataResponse.growthLastPrice);
          setEtaLastIncomePrice(etaMetaDataResponse.incomeLastPrice)
        }
      });
    }
  }, [activeSecurity, eta]);


  useEffect(() => {
    if (!isEmpty(activeETAList)) {
      onChangeDivision(activeETAList.etaType, true);
      updateETADetails(activeETAList);
      setEtaLastGrowthPrice(activeETAList.growthLastPrice);
      setEtaLastIncomePrice(activeETAList.incomeLastPrice)
    }
  }, [activeETAList]);



  const onChangeDivision = (value, skipResettingMarketListFlag) => {
    if (value === dropdownPlaceHolder) {
      value = '';
    }
    setETA(value);
    updateETAValue(value);
    const activeETA = filter(etaDropdownData, ['id', value])[0];
    if (!isEmpty(activeETA)) {
      setSectionLogo(activeETA.imagePath);
      updateETALogo(activeETA.imagePath);
    } else {
      setSectionLogo(initialSectionLogo);
      updateETALogo("");
    }
    triggerNextStep()
  }
  const triggerNextStep = () => {
    setHideNextStep(true);
    triggerOptimiseBlock(true);
  }

  return (
    <>
      <div className={cn('alternate-position w-full', className)}>
        <div className='section-header'>
          <div className="section-label">{sectionTitle}</div>
          <p className="section-description pt-2">
            {sectionDescription}
          </p>
          <div className='step-info mt-4'>
            <span className='step-label'>
              STEP 2
            </span>
            <span className='step-description pl-2'>
              {stepInfo}
            </span>
          </div>
          <div className='section-body pt-4'>
            <div className='section-logo py-4 flex justify-center'>
              <img src={sectionLogo} alt='title' />
            </div>
            <div className='eta-dropdown flex justify-center pt-4'>
              <Dropdown
                placeHolder={dropdownPlaceHolder}
                type={"eta"}
                data={etaDropdownData}
                handleChange={onChangeDivision}
                selectedItem={eta}
                className='eta-dropdown-option'
                isDisabled={isUndefined(growthETA) ? isEmpty(growthETA) : isEmpty(growthETA.toString())}
              />
            </div>
            <div className='security-info pt-2 invisible'></div>
            {!isEmpty(eta) && !isEmpty(etaLastGrowthPrice.toString()) && !isEmpty(etaLastIncomePrice.toString()) && (
              <>
                <div className='security-logo py-12 flex justify-center'>
                  <div className='w-4/12 justify-center flex'>
                    <CustomImage
                      ImagePath={`/logos/${activeSecurityContent.region}/${activeSecurityContent.ticker}${whiteSecurityImageSuffix}.svg`}
                      alt={activeSecurityContent.ticker}
                      defaultImagePath={`/logos/logoPending.svg`}
                      width="83%"
                      defaultImageWidth={125}
                    />
                  </div>
                </div>
                <div className='eta-details p-3'>
                  <div className='flex justify-between'>
                    <span className='eta-header'>ETA HOLDINGS</span>
                    <span className='eta-header'>ETA SECURITY</span>
                    <span className='eta-header'>PRICE PER ETA</span>
                  </div>
                  <div className='flex justify-between eta-info p-2 mt-1'>
                    <span>
                      <Statistic className='statistic-content' value={growthETA} />
                    </span>
                    <span className='eta-label'>
                      {etaTitleMapping[capitalize(eta)].growthTitle}
                      <span className='eta-logo inline-flex items-center ml-1.5 justify-center px-2 py-1 text-xs font-bold leading-none text-white rounded' style={{ background: etaTitleMapping[capitalize(eta)].growthColor }}> ETA </span>
                    </span>
                    <span>
                      <CurrencyFormat value={etaLastGrowthPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
                    </span>
                  </div>
                  <div className='flex justify-between eta-info p-2 mt-2'>
                    <span>
                      <Statistic className='statistic-content' value={incomeETA} />
                    </span>
                    <span className='eta-label'>
                      {etaTitleMapping[capitalize(eta)].incomeTitle}
                      <span className='eta-logo inline-flex items-center ml-1.5 justify-center px-2 py-1 text-xs font-bold leading-none text-white rounded' style={{ background: etaTitleMapping[capitalize(eta)].incomeColor }}> ETA </span>
                    </span>
                    <span>
                      <CurrencyFormat value={etaLastIncomePrice} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
                    </span>
                  </div>
                </div>
                {!hideNextStep && (
                  <div className='footer-info pt-6'>
                    <Button type="primary" onClick={() => triggerNextStep()} >
                      NEXT STEP
                      <img className='next-icon' src={arrowRightSmall} alt='next-icon'></img>
                    </Button>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AlternatePosition;