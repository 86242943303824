import React,{Component} from 'react'

export class LogoDark extends Component{

    render(){
        return(
        <svg
            width="265"
            height="23"
            viewBox="0 0 306 23"
            className="prism-header"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M305.076 8.48294H0V9.03655H305.076V8.48294Z"
              fill="url(#paint0_linear_1189_345)"
            />
            <path
              d="M151.52 0.187531H149.186V7.15278H151.52V0.187531Z"
              fill="#2C2F33"
            />
            <path
              d="M151.52 10.367H149.186V22.2791H151.52V10.367Z"
              fill="#2C2F33"
            />
            <path
              d="M249.789 10.367H247.549V22.2791H249.789V10.367Z"
              fill="#2C2F33"
            />
            <path
              d="M270.496 10.367H268.255V22.2791H270.496V10.367Z"
              fill="#2C2F33"
            />
            <path
              d="M108.952 7.76539C108.952 3.02856 105.539 0.181793 99.9263 0.181793H92.6216V2.20603H99.8621C104.279 2.20603 106.613 4.22444 106.613 7.75956C106.613 11.2947 104.279 13.2781 99.8621 13.2781H92.6216V15.2673H99.9263C100.511 15.2663 101.095 15.2351 101.677 15.174L106.723 22.2734H109.279L103.882 14.6898C107.12 13.6631 108.952 11.2305 108.952 7.76539Z"
              fill="#2C2F33"
            />
            <path
              d="M42.8072 2.21177C46.9613 2.21177 49.2717 3.99683 49.5343 7.15278H51.868C51.5822 2.78929 48.239 0.187531 42.8714 0.187531H34.5981V5.8694V11.4521V22.2792H36.9319V15.2789H42.8655C47.3522 15.2789 50.4211 13.4647 51.4771 10.3437H48.98C48.0407 12.193 45.9578 13.2313 42.8013 13.2313H36.9378V2.21177H42.8072Z"
              fill="#2C2F33"
            />
            <path
              d="M194.158 5.99775C194.158 3.78684 195.955 1.99012 199.992 1.99012C202.145 2.01271 204.247 2.64589 206.054 3.81601L206.842 1.95511C204.797 0.651278 202.417 -0.0278782 199.992 0.000876705C194.374 0.000876705 191.824 2.80681 191.824 6.06192C191.824 14.2289 205.424 10.5071 205.424 16.5623C205.424 18.7382 203.627 20.4708 199.491 20.4708C196.573 20.4708 193.779 19.3391 192.203 17.7932L191.287 19.5899C192.927 21.2933 196.177 22.46 199.491 22.46C205.138 22.46 207.729 19.6832 207.729 16.434C207.758 8.39534 194.158 12.053 194.158 5.99775Z"
              fill="#2C2F33"
            />
            <path
              d="M268.57 0.187627L264.725 6.82037L260.449 14.1881L259.072 16.5682L257.613 14.0831L253.366 6.8262L249.474 0.181793V0.187627H247.549V7.15288H249.795L249.801 4.39361L251.598 7.52039L255.017 13.4648L258.471 19.4675H259.574L268.255 4.60945V7.15288H270.496V0.187627H268.57Z"
              fill="#2C2F33"
            />
            <defs>
              <linearGradient
                id="paint0_linear_1189_345"
                x1="0"
                y1="8.76266"
                x2="305.076"
                y2="8.76266"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#D14D29" />
                <stop offset="0.14" stop-color="#E4D432" />
                <stop offset="0.28" stop-color="#B8D65E" />
                <stop offset="0.43" stop-color="#5D9340" />
                <stop offset="0.47" stop-color="#67A062" />
                <stop offset="0.52" stop-color="#77B597" />
                <stop offset="0.57" stop-color="#80C2B8" />
                <stop offset="0.59" stop-color="#84C7C4" />
                <stop offset="0.62" stop-color="#5EA8B4" />
                <stop offset="0.65" stop-color="#3D8DA6" />
                <stop offset="0.68" stop-color="#25799B" />
                <stop offset="0.7" stop-color="#176D95" />
                <stop offset="0.73" stop-color="#126993" />
                <stop offset="0.86" stop-color="#9680BA" />
                <stop offset="1" stop-color="#661E4D" />
              </linearGradient>
            </defs>
          </svg>
          )
    }
}


export default LogoDark;