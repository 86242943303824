import './styles.css';
import {defaultConfig, entireConfig, maxGrowthConfig, pureGrowthConfig, growthGuardConfig, maxDivConfig, pureDivConfig, divGuardConfig} from './utils'
import { Api } from './lib/index.module.js'
export const viewApi = Api;
export function CubeModelo() {
  window.loaded = false;


  return (
     <div className="App">
       <div className="">
         <div className="canvas-container">
           <canvas className="main-canvas"></canvas>
         </div>
         <div className="hidden home section"></div>
         <div className="reusable-elements"></div>
         <div className="hidden loader section">
           <div className="loader__group">
             <div className="loader__animation"></div>
             <div className="loader__progress-bar">
               <div className="loader__progress-bar-fill"></div>
             </div>
           </div>
         </div>
       </div>
       <div>
          {
              window.addEventListener('DOMContentLoaded', (event) => {
                  if (!window.loaded)
                  {
                      viewApi.init();
                      viewApi.import_color_config(entireConfig);
                      defaultZoneSelect(0);
                      window.viewApi = viewApi;
                      window.loaded = true;
                  }
              })
          }
        </div>
    </div>
  );
}

export function setCameraPosition(x,y){
    viewApi.set_camera_position(x,y);
}

export function stopRenderCube(){
    hideModelCube();
}

export function hideModelCube(){
    viewApi.stop();
}

//  Starts the cube render
// **
export function startRenderCube(){
    viewApi.start();
    viewApi.set_cube_opacity(1.0);
}

//  Determines the cube proportions through a normalised slider value
//  Converts to decimal units (0.01 -> 1);
// **
export function setNormalizedCubeSlider(etaType, activeEtaType, val){
          if (etaType === 'green') {
            if(activeEtaType === 'growth'){
              viewApi.set_slider(1 - (val/ 100));
              } else {
              viewApi.set_slider(val/100);
              }
          } else if (etaType === 'blue') {
            if(activeEtaType === 'growth'){
              viewApi.set_slider(1 - (val/ 100));
              } else {
              viewApi.set_slider(val/100);
              }
          } else  {
            if(activeEtaType === 'growth'){
              viewApi.set_slider(1 - (val/ 100));
              } else {
              viewApi.set_slider(val/100);
              }
          }
 }

//  Selects colour greenPerspective for onClick to interact with ThreeJS Model
// **
export function greenPerspectiveZone() {
        let profile = "GreenPerspective";
        /*
        viewApi.import_color_config(
        appendScaleJson(
        entireConfig,profile,scaleVariable)); */

        viewApi.set_color_config(profile, 0.5);
}

//  Selects colour BluePerspective for onClick to interact with ThreeJS Model
// **
export function bluePerspectiveZone() {
        let profile = "BluePerspective";
        /*
        viewApi.import_color_config(
        appendScaleJson(
        entireConfig,profile,scaleVariable)); */

        viewApi.set_color_config(profile, 0.5);
}

//  Selects colour RedPerspective for onClick to interact with ThreeJS Model
// **
export function redPerspectiveZone() {
        let profile = "RedPerspective";
        /*
        viewApi.import_color_config(
        appendScaleJson(
        entireConfig,profile,scaleVariable)); */

        viewApi.set_color_config(profile, 0.5);
}

//  Selects colour MAXDIV for onClick to interact with ThreeJS Model with MaxDiv Colour
// **
export function maxDivZoneSelect(scaleVariable) {
        let profile = "MaxDiv";
        /*
        viewApi.import_color_config(
        appendScaleJson(
        entireConfig,profile,scaleVariable)); */
       
        viewApi.set_color_config(profile, scaleVariable/ 100);
}

//  Selects colour PUREDIV for onClick to interact with ThreeJS Model with pureDiv Colour
// **
export function pureDivZoneSelect(scaleVariable) {
        let profile = "PureDiv";
        /*
        viewApi.import_color_config(
        appendScaleJson(
        entireConfig,profile,scaleVariable));
        */
       
        viewApi.set_color_config(profile, scaleVariable/ 100);
}

//  Selects colour DIVGUARD for onClick to interact with ThreeJS Model with divGuard Colour
// **
export function divGuardZoneSelect(scaleVariable) {
        let profile = "DivGuard";
        /*
        viewApi.import_color_config(
        // Method to append scale into the config json and set the color-config
        appendScaleJson(
        entireConfig,profile,scaleVariable));
        */
       
        viewApi.set_color_config(profile, scaleVariable/ 100);
}

//  Selects colour GROWTHGUARD for onClick to interact with ThreeJS Model with growthGuard Colour
// **
export function growthGuardZoneSelect(scaleVariable) {
        let profile = "GrowthGuard";
        /*
        viewApi.import_color_config(
        // Method to append scale into the config json and set the color-config
        appendScaleJson(
        growthGuardConfig,profile,scaleVariable));*/
       
        viewApi.set_color_config(profile, scaleVariable/ 100);
}

//  Selects colour PUREGROWTH for onClick to interact with ThreeJS Model with pureGrowth Colour
// **
export function pureGrowthZoneSelect(scaleVariable) {
        let profile = "PureGrowth";
        /*
        viewApi.import_color_config(
        // Method to append scale into the config json and set the color-config
        appendScaleJson(
        pureGrowthConfig,profile,scaleVariable));*/
       
        viewApi.set_color_config(profile, scaleVariable/ 100);
}

//  Selects colour MAXGROWTH for onClick to interact with ThreeJS Model with maxGrowth Colour
// **
export function maxGrowthZoneSelect(scaleVariable) {
        let profile = "MaxGrowth";
        /*
        viewApi.import_color_config(
        // Method to append scale into the config json and set the color-config
        appendScaleJson(
        maxGrowthConfig,profile,scaleVariable));*/

        viewApi.set_color_config(profile, scaleVariable/ 100);
}

//  Selects colour DEFAULT for onClick to interact with ThreeJS Model with pureGrowth Colour
// **
export function defaultZoneSelect(scaleVariable) {
        let profile = "Default";
        /*
        viewApi.import_color_config(
        // Method to append scale into the config json and set the color-config
        appendScaleJson(
        defaultConfig,profile,scaleVariable));*/
       
        viewApi.set_color_config(profile, scaleVariable/ 100);
}

//  Allows appending to json
function appendScaleJson(config,profile,scaleVariable){
     // Append the scale in the config json attribute
     // **
     config['config_id'][profile].scale = scaleVariable/100;
     return config;
}
export default CubeModelo;
