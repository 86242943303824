import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import { HEADER, INTRODUNCTION, ETA_PRICING, ETA_SPECTRUM, LIQUIDITY_AND_SECURITY, ABOUT, WHAT_ARE_ETAS } from "./utils";

const Intro = () => {

  const whatAreEtasRef = useRef();
  const etaPricingRef = useRef();
  const etaSpectrumRef = useRef();
  const liquidityRef = useRef();
  const aboutRef = useRef();

  const [introContainerHeight, setIntoContainerHeight] = useState(0);
  const [introHeaderHeight, setIntroHeaderHeight] = useState(0);
  const [contentHeaderHeight, setContentHeaderHeight] = useState(0);
  const [introContentHeight, setIntroContentHeight] = useState(0);

  const introContainerRef = useRef();
  const introHeaderRef = useRef();
  const contentHeaderRef = useRef();

  useEffect(() => {
    updateHeightOfDivs();
    window.addEventListener("resize", updateHeightOfDivs, false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateHeightOfDivs = () => {
    setIntoContainerHeight(introContainerRef?.current?.clientHeight);
    setIntroHeaderHeight(introHeaderRef?.current?.clientHeight);
    setContentHeaderHeight(contentHeaderRef?.current?.clientHeight);    
  }

  useEffect(() => {
    setIntroContentHeight(`${introContainerHeight - ((introHeaderHeight + contentHeaderHeight) + 20)}px`);
  }, [introContainerHeight, introHeaderHeight, contentHeaderHeight])

  const handleScroll = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
  };

  return (
    <div className="intro">
      <div ref={introContainerRef} className="intro__container">
        <div ref={introHeaderRef} className="intro__header flex flex-row w-full pb-8">
          <div className="scrollMenu" onClick={() => handleScroll(whatAreEtasRef)}> What are ETAs </div>
          <div className="scrollMenu" onClick={() => handleScroll(etaPricingRef)}> ETA Pricing </div>
          <div className="scrollMenu" onClick={() => handleScroll(etaSpectrumRef)}> ETA Spectrum </div>
          <div className="scrollMenu" onClick={() => handleScroll(liquidityRef)}> Liquidity & Security </div>
          <div className="scrollMenu" onClick={() => handleScroll(aboutRef)}> About </div>
        </div>
        <div ref={contentHeaderRef} className="content__header">
          <div className="headerText">
            {HEADER}
          </div>
        </div>
        <div style={{'maxHeight': introContentHeight}} className="intro__content text-sm leading-6">
          <div className="content__intro pb-8">
            <div className="introduction">
              {INTRODUNCTION}
            </div>
          </div>
          <div className="content__data">
            <div ref={whatAreEtasRef} className="whatAreEtas pb-8">
              {WHAT_ARE_ETAS}
            </div>
            <div ref={etaPricingRef} className="etaPricing pb-8">
              {ETA_PRICING}
            </div>
            <div ref={etaSpectrumRef} className="etaSpectrum pb-4">
              {ETA_SPECTRUM}
            </div>
            <div ref={liquidityRef} className="liquidityAndSecurity pb-4">
              {LIQUIDITY_AND_SECURITY}
            </div>
            <div ref={aboutRef} className="about pb-8">
              {ABOUT}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Intro;