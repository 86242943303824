import React,{Component} from 'react'

export class LogoWhite extends Component{

    render(){
        return(
            <svg width="265" height="27" viewBox="0 0 357 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M357 9.92816H0V10.5768H357V9.92816Z" fill="url(#paint0_linear_969_83)"/>
            <path d="M177.309 0.219543H174.578V8.37151H177.309V0.219543Z" fill="white"/>
            <path d="M177.309 12.1334H174.578V26.075H177.309V12.1334Z" fill="white"/>
            <path d="M292.303 12.1334H289.681V26.075H292.303V12.1334Z" fill="white"/>
            <path d="M316.534 12.1334H313.912V26.075H316.534V12.1334Z" fill="white"/>
            <path d="M127.496 9.08838C127.496 3.5445 123.502 0.212708 116.934 0.212708H108.386V2.58183H116.858C122.027 2.58183 124.758 4.94413 124.758 9.08156C124.758 13.219 122.027 15.5403 116.858 15.5403H108.386V17.8685H116.934C117.618 17.8672 118.301 17.8308 118.982 17.7592L124.888 26.0682H127.878L121.563 17.1925C125.352 15.9909 127.496 13.1439 127.496 9.08838Z" fill="white"/>
            <path d="M50.0929 2.58867C54.954 2.58867 57.6577 4.67786 57.9649 8.37151H60.6959C60.3614 3.26458 56.4493 0.219543 50.168 0.219543H40.4867V6.86947V13.4033V26.0751H43.2177V17.8821H50.1612C55.4115 17.8821 59.0027 15.7588 60.2385 12.1061H57.3163C56.2171 14.2704 53.7797 15.4857 50.0861 15.4857H43.2245V2.58867H50.0929Z" fill="white"/>
            <path d="M227.204 7.01963C227.204 4.43203 229.306 2.32919 234.031 2.32919C236.55 2.35563 239.01 3.09669 241.125 4.46617L242.046 2.28822C239.654 0.762241 236.868 -0.0326281 234.031 0.00102608C227.456 0.00102608 224.473 3.28502 224.473 7.09474C224.473 16.6532 240.387 12.2972 240.387 19.3841C240.387 21.9308 238.284 23.9585 233.444 23.9585C230.03 23.9585 226.76 22.634 224.916 20.8247L223.844 22.9276C225.763 24.9212 229.566 26.2867 233.444 26.2867C240.053 26.2867 243.084 23.0368 243.084 19.2339C243.118 9.82571 227.204 14.1065 227.204 7.01963Z" fill="white"/>
            <path d="M314.281 0.219535L309.781 7.98233L304.777 16.6054L303.166 19.391L301.459 16.4825L296.488 7.98916L291.934 0.212708V0.219535H289.681V8.3715H292.31L292.317 5.14212L294.42 8.80163L298.421 15.7588L302.462 22.7842H303.753L313.912 5.39473V8.3715H316.534V0.219535H314.281Z" fill="white"/>
            <defs>
            <linearGradient id="paint0_linear_969_83" x1="0" y1="10.2559" x2="357" y2="10.2559" gradientUnits="userSpaceOnUse">
            <stop stop-color="#D14D29"/>
            <stop offset="0.14" stop-color="#E4D432"/>
            <stop offset="0.28" stop-color="#B8D65E"/>
            <stop offset="0.43" stop-color="#5D9340"/>
            <stop offset="0.47" stop-color="#67A062"/>
            <stop offset="0.52" stop-color="#77B597"/>
            <stop offset="0.57" stop-color="#80C2B8"/>
            <stop offset="0.59" stop-color="#84C7C4"/>
            <stop offset="0.62" stop-color="#5EA8B4"/>
            <stop offset="0.65" stop-color="#3D8DA6"/>
            <stop offset="0.68" stop-color="#25799B"/>
            <stop offset="0.7" stop-color="#176D95"/>
            <stop offset="0.73" stop-color="#126993"/>
            <stop offset="0.86" stop-color="#9680BA"/>
            <stop offset="1" stop-color="#661E4D"/>
            </linearGradient>
            </defs>
            </svg>
            
          )
    }
}


export default LogoWhite;