import React from 'react'
import { Switch, Route, BrowserRouter,Redirect } from 'react-router-dom';
import Login  from '../components/login';
import Register from '../components/register';
import LandingPage from '../components/landing-page';
import EquityCalculator from '../components/equityCalculator/index';
import CanyonChart from '../components/Canyon/Chart/index';
import PayoffChart from '../components/payoff-chart/Chart/';
import EquityConverter from '../components/EquityConverter';
import ShowCase from "../components/ShowCase";
import MarketList from '../components/MarketList';
import MarketListLayout from '../components/MarketListLayout';


function Routes(){
    return(
        <BrowserRouter>
        <Switch>
        <Route exact path='/'><Redirect to={"/login"} /></Route>
        <Route exact path = "/login" component = {Login}/>
        <Route exact path = "/register" component = {Register}/>
        <Route exact path = "/equity-converter" component = {EquityConverter}/>
        <Route path='/home' component={MarketListLayout} />
        <Route path='/landing-page' component={LandingPage} />
        <Route path='/equity-calculator' component={EquityCalculator} />
        <Route path='/canyon' component={CanyonChart} />
        <Route path='/payoff-chart' component={PayoffChart} />
        <Route path='/prism-perspective' component={ShowCase}/>
        <Route exact path='/market-list' component={MarketList} />
        <Route exact path='/layout' component={MarketListLayout} />
        </Switch>
        </BrowserRouter>
    )
}

export default Routes;