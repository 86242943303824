import React from 'react';

const CustomImage = ({ ImagePath, width, alt, defaultImagePath, defaultImageWidth = 100, clickActionCallback = '', className }) => {
  return (
      <img 
        className={className}
        src={ImagePath} width={width} alt={alt}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src=defaultImagePath;
          currentTarget.width=defaultImageWidth;
        }}
        onClick={() => 
          {
            if(clickActionCallback !== '' && typeof clickActionCallback === 'function') {
              clickActionCallback()
            }
          }
        }
      />
  );
}

export default CustomImage;   