import React, { Component } from "react";
import { Input, Button, message } from 'antd';
import { UserOutlined, LockOutlined, FacebookOutlined, GoogleOutlined, LinkedinOutlined } from '@ant-design/icons';
import { authenticateEndPoint } from '../../common/endpoint';
import Logo from "../logo/dark";
import "antd/dist/antd.css";
import './style.scss';
import axiosConfig from '../../axiosConfig';

export class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: ""
    }
  }
  onChangeUsername = (e) => {
    this.setState({
      username: e.target.value
    });
  }
  onChangePassword = (e) => {
    this.setState({
      password: e.target.value
    });
  }
  loginSubmit = () => {
    let config = {
      headers: {
        "Content-Type": "application/json",
      }
    }
    const { username, password } = this.state;


    let data = {
      username: username,
      password: password
    }
    axiosConfig.post(authenticateEndPoint, data, config)
      .then(response => {
        localStorage.setItem("username", username);
        localStorage.setItem("pstack", JSON.stringify(response.data.jwttoken));
        this.props.history.push("/home");
        message.success(`Welcome ${username}`)
      })
      .catch((error) => {
        message.error("Invalid cred")
      })
  }

  render() {
    const { username, password } = this.state;
    return (
      <div className="login-container">
        <div className="login-body">
          <div className="left">
            <div className="left-header">
              <Logo />
            </div>
            <div className="center-layout">
              <p className="Sign-in-header">Sign in</p>

              <Input
                size="large"
                className="username"
                placeholder="Username"
                onChange={this.onChangeUsername}
                value={username}
                prefix={<UserOutlined />}
              />
              <Input
                size="large"
                className="passowrd"
                placeholder="Password"
                type="password"
                onChange={this.onChangePassword}
                value={password}
                prefix={<LockOutlined className="site-form-item-icon" />}
              />

              <Button className="sign-in-button" onClick={this.loginSubmit}>
                Sign In
              </Button>
              <p className="register-header">Don't have an account?<Button className="register-button" type="link" onClick={() => { this.props.history.push("/register") }}>
                Register
              </Button></p>

              <p className="other-signin-header">or sign in with</p>
              <div className="other-signin">
                <GoogleOutlined className="google-icon" onClick={() => {
                  console.log(" Google icon is clicked ")
                }} />

                <LinkedinOutlined className="linked-in-icon" onClick={() => {
                  console.log(" Linked In icon is clicked ")
                }} />

                <FacebookOutlined className="facebook-icon" onClick={() => {
                  console.log(" facebook icon is clicked ")
                }} />

              </div>
            </div>
          </div>
          <div className="right">
            <div className="right-header">
              <header className="prism-header">PRISM</header>
              <header className="work-header">Work at </header>
            </div>
            <div className="center-layout">
            </div>
          </div>
        </div>
        <div className="login-footer">
          <div className="content">
            <p className="general-enquires">General Enquiries:</p>
            <p>support@prism.martkets</p>
            <p>© 2021 Prism Markets. All rights reserved.</p>
            <p>PRISM MARKETS Limited is a company incorporated and registered in xxx (Company number: xxxxx) with its registered address at:  Level 14, 167 Macquarie St, Sydney, NSW, 2000</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;