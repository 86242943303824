import fadedHorizontalLine from '../../images/intro/faded_horizontal_line.png';
import ETAPricing from '../../images/intro/ETA_pricing.png';
import Liquidity from '../../images/intro/Liquidity.png';
import About from '../../images/intro/About.png';
import MaxGrowth from '../../images/cubes/MaxGrowth.png';
import DivGuard from '../../images/cubes/DivGuard.png';
import PureDiv from '../../images/cubes/PureDiv.png';
import PureGrowth from '../../images/cubes/PureGrowth.png';
import GrowthGuard from '../../images/cubes/GrowthGuard.png';
import MaxDiv from '../../images/cubes/MaxDiv.png';
import RedPairing from '../../images/cubes/RedPairing.png';
import BluePairing from '../../images/cubes/BluePairing.png';
import GreenPairing from '../../images/cubes/GreenPairing.png';
import { EtaColorCode } from '../payoff-chart/constants';
import cn from 'classnames';

export const getHorizontalLine = (classname = 'horizontalLine') => (<img src={fadedHorizontalLine} alt={classname} className={cn(classname, 'w-full')} />);

export const HEADER = <><div className="introducing leading-8">Introducing</div><div className="text-4xl">Exchange Traded Allocations <strong>ETAs</strong></div><div className="text-3xl font-extralight leading-10"> The Next Evolution of Investing</div>
  {getHorizontalLine()}
</>;

export const INTRODUNCTION = <><p><strong>Shares offer investors 100% of the growth, 100% of the dividend and 100% of the risk. Yet, few investors prioritise growth, dividends and risk equally.</strong> At Prism we questioned why investors should pay the same, when the purpose of their investments are so distinctly different.</p>
  <p>As such, the Prism ETA was created. Applicable across Australia's most traded shares, Prism Exchange Trade Allocations (ETAs) allow investors to focus on the single element that is most important to them - growth or dividend with a choice of risk. A conduit between whole assets and the value of their component parts, Prism ETAs allow investors to trade out of what's no longer serving them for more of what is.</p></>

export const WHAT_ARE_ETAS = (
  <>
    <div className='flex flex-row items-center'>
      <div className="w-1/5 font-light">What are <span className='font-extrabold text-2xl'>ETAs?</span></div>
      <div className="w-4/5 flex gap-2">
        <div className='w-1/6'>
          <img src={MaxGrowth} alt={MaxGrowth} />
        </div>
        <div className='w-1/6'>
          <img src={DivGuard} alt={DivGuard} />
        </div>
        <div className='w-1/6'>
          <img src={PureDiv} alt={PureDiv} />
        </div>
        <div className='w-1/6'>
          <img src={PureGrowth} alt={PureGrowth} />
        </div>
        <div className='w-1/6'>
          <img src={GrowthGuard} alt={GrowthGuard} />
        </div>
        <div className='w-1/6'>
          <img src={MaxDiv} alt={MaxDiv} />
        </div>
      </div>
    </div>
    {getHorizontalLine()}
    <p>
      ETAs are individually tradeable securities that allocate the values and risks of a share in a new way. Prism has created six ETAs. Each ETA reveals a new perspective of one specific share. Prism ETAs empower investors with greater precision over the design of investment strategies.
    </p>
    <p>ETAs allow investors to: </p>
    <ul className='ulStyle'>
      <li>Amplify their yield</li>
      <li>Accelerate their growth</li>
      <li>Unlock value from their existing share portfolio</li>
    </ul>
    <p>
      ETAs are available over a wide selection of Australia's most traded shares - such as CBA and BHP. While these shares may be familiar, the level of precision that ETAs offer has never been seen before.
    </p>
    <p>
      Bought like a share and traded like a share, ETAs can be traded on the stock exchange at any time throughout their 10-year duration.
    </p>
    <p>
      ETAs are designed to suit the objectives of both growth and dividend investors across a range of risk appetites.
    </p>
    <p>
      Growth investors can choose from three ETAs (for each underlying share). <strong>Each Growth ETA offers exposure to 100% of the capital growth from the underlying share, with risk profiles of:</strong>
    </p>
    <ul className='ulStyle'>
      <li>Market exposure</li>
      <li>Aggressive exposure</li>
      <li>Guarded exposure</li>
    </ul>
    <p>
      Dividend investors can choose from three ETAs (for each underlying share). <strong>Each Dividend ETA offers exposure to 100% of the dividend distributions from the underlying share, with risk profile of:</strong>
    </p>
    <ul className='ulStyle'>
      <li>Market exposure</li>
      <li>Aggressive exposure</li>
      <li>Guarded exposure</li>
    </ul>

    <p className='text-xl font-light'>Leveraging the Power of Connection</p>
    <p>
      ETAs are fully secured by the underlying shares they cover and are priced using "Prism Leverage". Prism leverage is a new form of natural, long-term leverage, powered by connecting two investor strategies within a single underlying share. Prism Leverage means both growth and dividend ETA investors are effectively 'sharing' the ownership of the underlying share for a mutually beneficial advantage. Prism Leverage is a natural opportunity to gain leverage without many of the inherent risks of traditional borrowed leverage, such as the risk of margin calls or short-term volatility.
    </p>
  </>
);

export const ETA_PRICING = <>
  <div className='flex w-full'>
    <div className='font-extrabold text-2xl w-4/6 pb-4 self-end'>ETA Pricing</div>
    <div className='w-2/6'>
      <img src={ETAPricing} alt={ETAPricing} />
    </div>
  </div>
  {getHorizontalLine("pb-4")}
  <p>
    ETAs are designed to appeal to a wide audience of investors from those looking for maximum growth to those wanting maximum income. ETAs connect investors through a single underlying share, balancing objectives through a shared commonality.
  </p>
  <p>
    ETAs are always fully secured by the underlying securities. ETAs are only issued to investors when the sum of both the growth and the dividend ETAs equal the full value of the underlying share in equal volumes.
  </p>
  <p>
    ETA investors are effectively combining their bids for the full cost of the underlying share. Prism will only issue ETAs in pairs, both the growth and the dividend, however investors can buy whichever ETAs are most aligned with their investing strategy.
  </p>
  <p>
    Investors bid for their desired ETA as a percentage of the price of the full underlying share. The Prism pricing tools have been designed to help investors determine a growth multiple or yield of their choosing.
  </p>
  <p className='text-lg font-light'>How it works:</p>
  <p>Imagine an ETA covering an underlying share which is trading at $100 with a dividend yield of 5.0%.</p>
  <p>The following outcomes could be achieved by each ETA investor.</p>

  <div className='p-4 mb-8 bg-white'>
    <p><u>A growth investor</u> bids $50 each for 1,000 PureGrowth ETAs</p>
    <p>
      Targeting a growth multiple of 2x, they will receive all the growth in the underlying share price (above the current price of $100), however they only pay $50 for the growth allocation.
    </p>
    <p><u>An income investor</u> bids $50 each for 1,000 PureDiv ETAs</p>
    <p>
      Targeting a yield of 10.0% from the underlying share, they will receive all dividend distributions in the underlying share (for the 10 years until maturity), however they only pay $50 for the dividend allocation.
    </p>
  </div>
  <p>The combined bid price of each ETA equals the full value of the underlying share. In this example, the volumes are equal, but where they are not, Prism would leave any surplus bids in the order book for another investor to match with.</p>
  <p>
    Once bids are matched, Prism secures the underlying shares which are held in custody on behalf of the ETA investors.  Once issued, the ETAs trade on the stock exchange.
  </p>
  <p>Prompt to interact with the cube: 'See the value within'</p>
  <p><strong>For Existing Shareholders</strong></p>
  <p>Prism is a conduit between whole shares and the value of their component parts.</p>
  <p>For existing shareholders, ETAs can be created by converting existing share holdings into an equal pair of Growth & Dividend ETAs, within the same colour perspective. Investors may then choose to sell either the growth or the dividend ETA at a price and volume of their choosing to release capital or further optimise their position.</p>

  <p><strong>Share Converter</strong></p>
  <p>Use our Share Converter tool to search for a share you wish to convert, input the quantity of your holding and see how ETAs can enhance your portfolio.</p>

  <p><strong>List of ETAs</strong></p>
  <p>ETAs currently cover 30 of the top listed Australian stocks and ETFs - names you already know. A full list of available ETAs can be found here. If you wish to convert shares that are not currently available, please contact Prism Markets for more information. (Link to contact)</p>

  <p><strong>Select your Colour Perspective</strong></p>
  <p>Existing shares can be converted into a pair of ETAs within the same colour perspective. Select the colour perspective below, to begin optimising.</p>
  <p>The ETA Decider can help you determine which colour perspective is best suited to your strategy.</p>

  <p><strong>Optimise Your Investment</strong></p>
  <p>In order to optimise your holding or release capital, you will need to sell one component of the ETA pairing. These funds can either be retained or used to purchase additional ETAs that are more aligned to your overall objective.</p>
  <p>The sliders allow you to optimise the weighting of your new ETA holding. You can choose to release capital or increase your exposure to Growth or Dividends.</p>

  <p><strong>Conversion Process</strong></p>
  <p>Investors with a broker sponsored HIN can deliver the underlying share to Prism via the pre-filled shareholder application. </p>
  <p>The underlying share will be held by the Prism Securities Trustee in a custody arrangement for you as the beneficial holder of the underlying security. (Hover or link to key partners page)</p>
  <p>After T+2 you will receive your ETAs in your brokered sponsored HIN.</p>
</>

export const ETA_SPECTRUM = <>
  <div className='flex flex-row items-center'>
    <div className="w-1/2 font-extrabold text-2xl">ETA SPECTRUM</div>
    <div className="w-1/2 flex gap-2">
      <div className='w-2/6'>
        <img src={RedPairing} alt={RedPairing} />
      </div>
      <div className='w-2/6'>
        <img src={BluePairing} alt={BluePairing} />
      </div>
      <div className='w-2/6'>
        <img src={GreenPairing} alt={GreenPairing} />
      </div>
    </div>
  </div>
  {getHorizontalLine()}
  <p>Prism has designed three different perspectives on one underlying share, represented through the colour allocations of red, green and blue. Each perspective, distinguished by colour, combines one Growth and one Dividend ETA. Each perspective allocates capital risk differently. This approach allows ETAs to appeal to the broadest range of investors.</p>
  <p>ETAs can only be paired within the same color perspective. For example, a blue Growth ETA can only be paired with a blue Dividend ETA. This ensures all values and risk exposures from the underlying share have been fully allocated.</p>
  <p>The three ETA colour perspectives allow investors to choose their level of capital exposure to the underlying share. Investors may decide to increase their upside exposure to a component of the shares value while retaining 1:1 downside exposure. Alternatively, investors may opt into more capital exposure in return for even greater upside OR out of exposure to guard a portion of their capital.</p>

  <p className='text-xl font-light'>Green Pairing</p>
  <div className='flex w-full items-center text-center'>
    <div className='w-1/6 flex flex-col'>
      <img src={PureDiv} alt={PureDiv} />

    </div>
    <div className='w-1/6 text-2xl font-extralight'>+</div>
    <div className='w-1/6 flex flex-col'>
      <img src={PureGrowth} alt={PureGrowth} />

    </div>
    <div className='w-1/6 text-2xl font-extralight'>=</div>
    <div className='w-1/6 flex flex-col'>
      <img src={GreenPairing} alt={GreenPairing} />

    </div>
  </div>
  <div className='flex w-full items-center pb-8'>
    <div className='w-2/6 flex flex-col'>
      <div>PureDiv <span className='font-bold' style={{ color: EtaColorCode["PureDiv"] }}>ETA</span></div>
    </div>
    <div className='w-2/6 flex flex-col'>
      <div>PureGrowth <span className='font-bold' style={{ color: EtaColorCode["PureGrowth"] }}>ETA</span></div>
    </div>
    <div className='w-2/6 flex flex-col'>
      <div>Green Pairing</div>
    </div>
  </div>

  <p><strong>Risk Exposure:</strong> Green ETAs allocate capital exposure equally to both growth and dividend investors - meaning, a 1:1 downside exposure to the underlying share for each investor.</p>

  <p className='text-xl font-light pt-4'>Blue Pairing</p>
  <div className='flex w-full items-center text-center'>
    <div className='w-1/6 flex flex-col'>
      <img src={DivGuard} alt={DivGuard} />
    </div>
    <div className='w-1/6 text-2xl font-extralight'>+</div>
    <div className='w-1/6 flex flex-col'>
      <img src={MaxGrowth} alt={MaxGrowth} />
    </div>
    <div className='w-1/6 text-2xl font-extralight'>=</div>
    <div className='w-1/6 flex flex-col'>
      <img src={BluePairing} alt={BluePairing} />
    </div>
  </div>
  <div className='flex w-full items-center pb-8'>
    <div className='w-2/6 flex flex-col'>
      <div>DivGuard <span className='font-bold' style={{ color: EtaColorCode["DivGuard"] }}>ETA</span></div>
    </div>
    <div className='w-2/6 flex flex-col'>
      <div>MaxGrowth <span className='font-bold' style={{ color: EtaColorCode["MaxGrowth"] }}>ETA</span></div>
    </div>
    <div className='w-2/6 flex flex-col'>
      <div>Blue Pairing</div>
    </div>
  </div>

  <p><strong>Risk Exposure:</strong> Blue ETAs allocate the upfront capital exposure to top and bottom layers.</p>

  <p><u>Top Layer:</u> MaxGrowth ETAs offer an aggressive risk profile. Investors are exposed to the first loss of capital in the event the underlying share was to fall at maturity.</p>
  <p><u>Bottom Layer:</u> DivGuard ETAs offer a conservative profile. A portion of their capital is guarded in the event the underlying share was to fall at maturity.</p>

  {/* Red Pairing */}
  <p className='text-xl font-light'>Red Pairing</p>
  <div className='flex w-full items-center text-center'>
    <div className='w-1/6 flex flex-col'>
      <img src={MaxDiv} alt={MaxDiv} />

    </div>
    <div className='w-1/6 text-2xl font-extralight'>+</div>
    <div className='w-1/6 flex flex-col'>
      <img src={GrowthGuard} alt={GrowthGuard} />

    </div>
    <div className='w-1/6 text-2xl font-extralight'>=</div>
    <div className='w-1/6 flex flex-col'>
      <img src={RedPairing} alt={RedPairing} />

    </div>
  </div>
  <div className='flex w-full items-center pb-8'>
    <div className='w-2/6 flex flex-col'>
      <div>MaxDiv <span className='font-bold' style={{ color: EtaColorCode["MaxDiv"] }}>ETA</span></div>
    </div>
    <div className='w-2/6 flex flex-col'>
      <div>GrowthGuard <span className='font-bold' style={{ color: EtaColorCode["GrowthGuard"] }}>ETA</span></div>
    </div>
    <div className='w-2/6 flex flex-col'>
      <div>Red Pairing</div>
    </div>
  </div>

  <p><strong>Risk Exposure:</strong> Red ETAs allocate the upfront capital exposure to top and bottom layers.</p>

  <p><u>Top Layer:</u> MaxDiv ETAs offer an aggressive risk profile. Investors are exposed to the first loss of capital in the event the underlying share was to fall at maturity.</p>
  <p><u>Bottom Layer:</u> GrowthGuard ETAs offer a conservative profile. A portion of their capital is guarded in the event the underlying share was to fall at maturity.</p>
</>

export const LIQUIDITY_AND_SECURITY = <>
  <div className='flex w-full'>
    <div className='font-extrabold text-2xl w-4/6 pb-4 self-end'>Liquidity & Security</div>
    <div className='w-2/6 relative top-8 headerLogo'>
      <img width={120} src={Liquidity} alt={Liquidity} />
    </div>
  </div>
  {getHorizontalLine("pb-8")}

  <p className='text-lg font-light'>A New Dimension of Liquidity</p>
  <p><strong>ETAs trade on the stock exchange, just like shares.</strong></p>

  <p>A pair of ETAs represents two parts of a whole share owned by different investors.  The sum of the paired ETA parts is directly correlated to the full value of the whole underlying share throughout their 10-year duration.</p>
  <p>Investors can trade the ETAs on the secondary market as they would any traditional share.</p>
  <p>Prism ETAs unlock another dimension of liquidity, that directly links to the volume and liquidity of the underling share. </p>
  <p>When an investor owns both ETA parts within a colour perspective, they may redeem the underlying share at any point throughout the 10-year duration by exchanging both ETA parts.</p>
  <p>This means that if the sum of the two parts were to ever be less than the value of the whole, any market participant (or market maker) could buy the two parts and arbitrage by redeeming the whole share.</p>
  <p>This feature ensures that ETAs investors always have access to immediate liquidity, that mirrors the volume and value of the underlying share.</p>

  <p className='text-lg font-light'>Security</p>
  <p>ETAs are always fully secured by the underlying share (which will be held in custody). Prism will ONLY issue ETAs when the sum of both Growth & Dividend ETAs equates to the full value of the underlying share in equal volumes.</p>

  <p className='text-lg font-light'>How to Buy and Sell ETAs</p>
  <p>Buying and selling ETAs is very similar to buying and selling shares. There are three key steps for investors to consider:</p>

  <p><strong>1. Select your ETAs</strong></p>
  <p>Firstly, investors should explore the range of available ETAs. ETAs cover the most traded shares and ETFs in Australia - so the names will be familiar.</p>
  <p>Use the Prism pricing tools to plan an investment and determine which ETA colour perspective best suits a market view.</p>

  <p><strong>2. Review available information</strong></p>
  <p>Always read the Product Disclosure Statement (PDS) (link) for the ETAs you are going to invest in. Be aware of the brokerage fee to trade on the exchange - this will vary depending on the broker you use and the size of the transaction. There are no custody fees involved. </p>

  <p><strong>3. Trade using a broker</strong></p>
  <p>Investing in ETAs is just like investing in shares. There's no minimum investment amount and no additional paperwork to set up accounts.</p>
  <p>To trade, just find the 6-digit ticker code for the ETA you want to invest in and use it to find the ETA on your broker platform. The ETA codes can be found in a number of places on the Prism website, including the Available ETAs table and the Prism pricing tools. ETAs can be traded through any broker that trades securities on the Exchange</p>

  <p className='text-lg font-light'>First time trading?</p>
  <p>For new investors there are a few terms to be aware of:</p>
  <p><strong>Code: </strong>This refers to the 6-digit ETA ticker code which identifies the specific ETA to buy.</p>
  <p><strong>Quantity: </strong>Enter the number of ETAs to buy. The exchange may impose a $500 minimum initial purchase for each ETA.</p>
  <p><strong>Price limit: </strong>Tick the box 'at market' to buy at the ETA's current price.</p>
  <p><strong>Expiry: </strong>Tick the box 'good for day' to buy at the ETA's closing trade for that day.</p>
  <p><strong>Any questions? </strong>Visit our FAQs page for more information about buying ETAs.</p>
</>

export const ABOUT = <>
  <div className='flex w-full'>
    <div className='font-extrabold text-2xl w-4/6 pb-4 self-end'>About</div>
    <div className='w-2/6 relative top-8'>
      <img src={About} alt={About} />
    </div>
  </div>
  {getHorizontalLine("pb-8")}

  <p>"The task is not so much to see what nobody has yet seen, but to think what nobody has yet thought, about that which everybody sees."</p>
  <p>(Erwin Schrödinger)</p>
  <p>Company Overview</p>
  <p>Founded in 2019, Prism Global Group Ltd is an Australian-based fintech company. Prism offers investors a new perspective on financial markets by revealing the parts within whole shares. Prism believes in the power of connection to unlock hidden potential, enabling financial markets to operate more efficiently through a whole new spectrum of choice.</p>
  <p><strong>A Synthesis of Innovation and Intent:</strong></p>
  <p>As technology increasingly integrates into our lives, influencing and informing the way we exist, Prism supports the evolution of investing by introducing a new suite of financial instruments - Exchange Traded Allocations (ETAs).</p>
  <p>By allowing investors the ability to design, analyse and trade the individual elements of growth and dividend, Prism ETAs provide investors with greater precision over their investment portfolios. Prism ETAs empower investors with greater choice, allowing investors to better align their objectives, transforming the way they invest.</p>
  <p>Prism - Powered by Connection.</p>
</>