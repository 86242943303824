import { toString, map, isEmpty } from 'lodash';

export const securityHeaderLabel= 'Select a share to reveal the values within';
export const securityAutoComplete= 'SEARCH SHARE NAME OR TICKER CODE';
export const GREEN = 'Green';
export const RED = 'Red';
export const BLUE = 'Blue';
export const prismAnnualFee = 0.003;
export const whiteSecurityImageSuffix = "_wht"
export const etaTitleMapping = {
    [GREEN]: {
        growthTitle: 'PureGrowth',
        incomeTitle: 'PureDiv',
        growthColor: 0xC7DB6D,
        incomeColor: 0x73BD59
    },
    [BLUE]: {
        growthTitle: 'MaxGrowth',
        incomeTitle: 'DivGuard',
        growthColor: 0x65CDF3,
        incomeColor: 0x1C59A8
    },
    [RED]: {
        growthTitle: 'GrowthGuard',
        incomeTitle: 'MaxDiv',
        growthColor: 0xDB3155,
        incomeColor: 0xF5BD1A
    }
}

export const etaDropdownData = [
  { 
    name: "Green Division", 
    id: "GREEN", 
    colorCode:'#73BD59'
  },
  { 
    name: "Red Division", 
    id: "RED", 
    colorCode:'#1C59A8'
  },
  { 
    name: "Blue Division", 
    id: "BLUE", 
    colorCode:'#DB3155'
  }
];


export const currencyFormat = (value) => {
   return new Intl.NumberFormat().format(Number(toString(value).replace(/[^0-9.-]+/g,"")));
}

/**
  * @description This methods used to parse the ETA Security details before rendering
  * @param {object} data Security details from the API
*/
export const parseSecurityData = (data) => {
  if(!isEmpty(data)) {
    map(data, (underlying) => {
      underlying.value = `${underlying.longName} (${underlying.ticker})`;
      underlying.text = `${underlying.longName} (${underlying.ticker})`;
    })
  }
  return data;
};

  /**
    * @description This object stores info about the Example Stock
  */
export const exampleSecurityContent = {
  "ticker": "EXS",
  "longName": "Example Stock",
  "forwardDivYield": 0.05,
  "lastPrice": 100.00,
  "marketCap": 10000.00,
  "region": "AU"
};

export const exampleRedETA = {
  "etaName": "EXS Red",
  "etaType": "RED",
  "underlyingSymbol": "EXS",
  "growthSymbol": "EXSPRG",
  "incomeSymbol": "EXSPRI",
  "growthLastPrice": 70,
  "incomeLastPrice": 30,
  "growthEstablishmentPrice": 70,
  "incomeEstablishmentPrice": 30,
  "growthMultiple": 1.43,
  "etaYield": 0.1667,
  "issueDate": "",
  "maturityDate": ""
};

export const exampleGreenETA = {
  "etaName": "EXS Green",
  "etaType": "GREEN",
  "underlyingSymbol": "EXS",
  "growthSymbol": "EXSPGG",
  "incomeSymbol": "EXSPGI",
  "growthLastPrice": 50.00,
  "incomeLastPrice": 50.00,
  "growthEstablishmentPrice": 50.00,
  "incomeEstablishmentPrice": 50.00,
  "growthMultiple": 2.00,
  "etaYield": 0.1000,
  "issueDate": "",
  "maturityDate": ""
};

export const exampleBlueETA = {
  "etaName": "EXS Blue",
  "etaType": "BLUE",
  "underlyingSymbol": "EXS",
  "growthSymbol": "EXSPBG",
  "incomeSymbol": "EXSPBI",
  "growthLastPrice": 30.00,
  "incomeLastPrice": 70.00,
  "growthEstablishmentPrice": 30.00,
  "incomeEstablishmentPrice": 70.00,
  "growthMultiple": 3.33,
  "etaYield": 0.0714,
  "issueDate": "",
  "maturityDate": ""
}
