import React from 'react';
import { useState } from 'react';
import cn from 'classnames';
import PageContent from "../common/ContentPage";
import SubHeader from '../common/subHeader';
import ETAShowCase from '../ETAShowCase';
import CubeModelo, { defaultZoneSelect, hideModelCube, stopRenderCube, startRenderCube } from '../ETAShowCase/ETACubeModelo/Index';
import EquityConverter from '../EquityConverter';
import Transition from "../EquityConverter/utils/Transition";
import './styles.scss';
import ETADetails from '../ETADetails';
import { activeEtaProfile } from '../ETADetails/utils';

const MarketListLayout = () => {
  /**
    * @description This methods used to set the Security details from the MarketList which will be passed to EquityConverter and Prism Perspective
    * @param {object} companyObj Active Secuirty details
  */
  const onSelectedCompanyHeader = (companyObj) => {
    setActiveSecurityList(companyObj);
  }

  /**
    * @description This methods used to set the ETA details from the MarketList which will be passed to EquityConverter and Prism Perspective
    * @param {object} etaTypeObj Active ETA details
  */
  const onSelectedEtaType = (etaTypeObj) => {
    setActiveETAList(etaTypeObj);
  }

  const [renderEquityConverter, setRenderEquityConverter] = useState(false);
  const [renderETAShowCase, setRenderETAShowCase] = useState(true);
  const [hideMarketList, setHideMarketList] = useState(false);
  const [activeSecurityList, setActiveSecurityList] = useState({});
  const [activeETAList, setActiveETAList] = useState({});
  const [etaType, setEtaType] = useState('')
  const [activeETAProfile, setActiveETAProfile] = useState({})

  /**
    * @description This methods used to navigate the page to render Equity Converter. This method also resets the Equity Converter to its initial stage
  */

  const triggerEquityConverter = () => {
    hideModelCube();
    setRenderEquityConverter(true);
    setRenderETAShowCase(false);
    setActiveSecurityList({});
    setActiveETAList({});
    //Resets the ETA Details to its initial state while navigating to the Equity Converter
    setEtaType('');
    setActiveETAProfile({});
    defaultZoneSelect(100);
  }

  /**
    * @description This methods used to update the Selected ETA details so that, the Prism Intro section will render the details about it
    * @param {string} etaColor Active ETA Color (Red, Blue or Green)
    * @param {string} etaType Active ETA Type (Growth or Income) 
    * @param {string} etaLabel Active ETA Label (PureDiv, PureGrowt and others) 
  */

  const updateETADetails = (etaColor, etaTypeFromView, etaLabelFromView) => {
    setEtaType(etaLabelFromView);
    if(etaTypeFromView !== '' && etaLabelFromView !== '') {
      setActiveETAProfile(activeEtaProfile(etaLabelFromView, etaTypeFromView)); 
    }
  }

  /**
    * @description This methods used to navigate the page to render Equity Converter. This method also resets the Equity Converter to its initial stage
  */
  const triggerETAShowCase = () => {
    setRenderEquityConverter(false);
    setRenderETAShowCase(true);
    setHideMarketList(false);
    setActiveSecurityList({});
    setActiveETAList({});
    startRenderCube();
  }

  /**
    * @description This methods to hide/show the Intro panel while viewing the Third step in Equity Converter
  */
  const updateMarketListView = (marketListState) => {
    setHideMarketList(marketListState);
  }

  return (
    <PageContent triggerEquityConverter={triggerEquityConverter} triggerETAShowCase={triggerETAShowCase}>
      <div className='main flex w-full '>
        <div className='flex flex-row w-full'>
          <Transition isHorizontal={true} className='flex flex-col w-1/3 market-menu eta-index-container' isOpen={!hideMarketList} >
            {!hideMarketList && (
              (etaType !== '') ? (
                <ETADetails etaType={etaType} etaProfile={activeETAProfile} onHandleBackButtonCallback={() => {setEtaType(''); setActiveETAProfile({})}} />
              ) : (
                <SubHeader onSelectedEtaTypeCallback={(value) => onSelectedEtaType(value)} onSelectedCompanyHeaderCallback={(value) => onSelectedCompanyHeader(value)} />
              )
            )}
          </Transition>

          <div className={cn('flex flex-col w-2/3 eta-index-container',
            {
              'md:w-8/12': !hideMarketList,
              'w-full': hideMarketList
            })
          }>
            <Transition isHorizontal={true} isOpen={renderETAShowCase} className='container-cube' >
                <CubeModelo />
              {renderETAShowCase && (
                <ETAShowCase activeSecurityList={activeSecurityList} activeETAList={activeETAList} updateETADetails={updateETADetails} />
              )}
            </Transition>
            <Transition isHorizontal={true} isOpen={renderEquityConverter} isMoveFromRight={true} className='w-2/3 eta-index-container' >
              {renderEquityConverter && (
                <EquityConverter
                  updateMarketListView={updateMarketListView}
                  includePageContent={false}
                  activeSecurityList={activeSecurityList}
                  activeETAList={activeETAList}
                />
              )}
            </Transition>
          </div>
        </div>
      </div>
    </PageContent>
  );
}

export default MarketListLayout;